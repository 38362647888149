import { getStorageImageUrl } from "@/utils/get-storage-image-url";
import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.light};

  width: 15%;
  padding: 0.5rem;

  align-items: center;

  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

export const Logo = styled.img.attrs({
  src: getStorageImageUrl("logo_sidebar.svg"),
})`
  width: 9rem;
  object-fit: contain;
  margin-top: 2rem;
`;
