//@ts-nocheck
import Theme from "@/utils/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { SummaryWrapper, UnskewWrapper } from "./styled-components";
import { ShapedAccordionProps } from "./props.type";
import "./styles.css";
import { useShapedAccordion } from "./useShapedAccordion";
import Spacer from "../Spacer";
import ChevronDown from "@/ui/icons/ChevronDown";
import Button from "../Button";
import { useParams, useNavigate } from "react-router-dom";
import { getStageChallenges } from "@/services/challenges";
import { useEffect, useState } from "react";

const ShapedAccordion = (props: ShapedAccordionProps) => {
  const { items = [], fixedIndex, summaryStyle } = props;
  const { handleChange, getExpandedValue } = useShapedAccordion(props);
  const { id } = useParams();

  const [nextChallenge, setNextChallenge] = useState(null);
  const [challenge, setChallenge] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchChallenges = async () => {
      const response = await getStageChallenges(1);

      const index = response.findIndex((item) => item.id === Number(id));

      setChallenge(response[index]);

      if (index + 2 === response[index + 1].order) {
        setNextChallenge(response[index + 1]);
      }
    };
    fetchChallenges();
  }, [id]);

  const goBack = () => {
    navigate("/challenges");
  };

  const handleType = () => {
    if (nextChallenge.type === "InPersonChallenge") {
      navigate(`/challenges/inPerson/${nextChallenge.id}`);
    }
    if (nextChallenge.type === "CaseChallenge") {
      navigate(`/challenges/case/${nextChallenge.id}`);
    }
    if (nextChallenge.type === "MediaChallenge") {
      navigate(`/challenges/media/${nextChallenge.id}`);
    }
  };
  const handleNextChallenge = () => {
    handleType();
  };

  return (
    <Box mx="0.5rem">
      {items.map((item, index) => {
        const isExpanded = getExpandedValue(index);
        const isFixed = fixedIndex === index;

        if (item.hidden) return;

        return (
          <>
            {!isExpanded && <Spacer height="1rem" />}
            <Accordion
              key={`accordion-item-${index}`}
              sx={{
                boxShadow: "none",
                position: "inherit",
              }}
              expanded={isExpanded}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={
                  isFixed ? null : (
                    <UnskewWrapper>
                      <ChevronDown color={Theme.colors.brand.secondary} />
                    </UnskewWrapper>
                  )
                }
                style={{
                  border: `1px solid ${Theme.colors.neutral.mid}`,
                  borderRadius: "0.2rem",
                  boxShadow: isExpanded
                    ? "none"
                    : `0.1rem 0.2rem 0px 0.1rem ${
                        item.shadowColor || Theme.colors.brand.primary
                      }`,
                  backgroundColor: Theme.colors.background.secondary,
                  ...summaryStyle,
                }}
                className="custom_summary"
              >
                <SummaryWrapper>{item.summary}</SummaryWrapper>
              </AccordionSummary>

              <AccordionDetails
                style={{
                  border: `1px solid ${Theme.colors.neutral.mid}`,
                  boxShadow: `0rem 0.3rem ${
                    item.shadowColor || Theme.colors.brand.primary
                  }`,
                  borderRadius: "0px 0px 0.5rem 0.5rem",
                  padding: "0px",
                }}
                className="custom_details"
              >
                {item.details}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
      {challenge && (
        <Box>
          <Spacer width="1rem" />
          {nextChallenge ? (
            <Box
              display="flex"
              mt="1rem"
              justifyContent="space-between"
              width="100%"
            >
              <Button
                sx={{
                  width: "20%",
                }}
                onClick={goBack}
                size="small"
                variant="outlined"
              >
                Sair
              </Button>

              <Button
                size="small"
                sx={{
                  width: "20%",
                }}
                variant="outlined"
                disabled={
                  challenge.status === "Pending" ||
                  challenge.status === "Rejected"
                }
                onClick={handleNextChallenge}
              >
                Próximo Desafio
              </Button>
            </Box>
          ) : (
            <Box display="flex" justifyContent="start" width="20%">
              <Button onClick={goBack} size="small" variant="outlined">
                Sair
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ShapedAccordion;
