import { useState, useRef } from "react";
import { Box, Card, CircularProgress, useMediaQuery } from "@mui/material";
import { QuizDetailsDialogProps } from "./props.type";
import Text from "@/components/Text";
import { useQuizQuestionsDialog } from "./useQuizQuestionsDialog";
import Theme from "@/utils/theme";
import { AlternativeBullet, AlternativeWrapper } from "./styles";
import Button from "@/components/Button";
import Spacer from "@/components/Spacer";
import Modal from "@/components/Modal";
import Dialog from "@/components/Dialog";
import QuizPointsDialog from "./components/QuizPointsDialog";
import InfoIcon from "@/ui/icons/InfoIcon";
import HighlightedText from "@/components/HighlightedText";
import {
  FullscreenButton,
  MediaTutorialWrapper,
  TutorialMedia,
} from "@/layout/ChallengeSubmissionWrapper/styles";
import FsLightbox from "fslightbox-react";
import { useTimer } from "react-timer-hook";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ProgressBar from "@ramonak/react-progress-bar";

const QuizQuestionsDialog = (props: QuizDetailsDialogProps) => {
  const { isOpen, challenge, onClose } = props;

  const [isProgressCase, setIsProgressCase] = useState(false);
  const [step, setStep] = useState(isProgressCase ? 1 : 0);
  const [isFullscreenImage, setIsFullscreenImage] = useState(false);
  const toggleFullscreen = () => setIsFullscreenImage(!isFullscreenImage);

  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const timeLeftRef = useRef<Date>(new Date());

  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    currentQuestion,
    isLastQuestion,

    currentAlternatives,
    currentAnswer,
    answers,
    getIsSelected,
    setCurrentQuestionAnswer,

    isOpenLeaveDialog,
    toggleLeaveDialog,
    onLeaveQuiz,
    // onPreviousQuestion,
    onNextQuestion,

    onSubmit,
    isLoading,
    totalCorrect,
    totalWrong,
  } = useQuizQuestionsDialog(props);

  const { seconds, minutes, restart, pause } = useTimer({
    autoStart: false,
    expiryTimestamp: timeLeftRef.current,
    onExpire: async () => {
      console.warn("onExpire called");
      setIsTimeExpired(true);
      await onSubmit();
    },
  });

  if (isTimeExpired) {
    return (
      <Dialog
        isOpen={isTimeExpired}
        icon={<InfoIcon color={Theme.colors.feedback.negative} />}
        text="Tempo esgotado! 
    O tempo limite para esse questionário chegou
     ao fim. Mas não se preocupe, todas 
     as respostas respondidas anteriormente foram salvas."
        onConfirm={() => {
          setIsTimeExpired(false);
        }}
      />
    );
  }

  if (challenge?.submission) {
    return (
      <QuizPointsDialog
        isOpen={isOpen}
        onClose={onClose}
        challenge={challenge}
        totalCorrect={totalCorrect}
        totalWrong={totalWrong}
      />
    );
  }

  return (
    <>
      <FsLightbox
        toggler={isFullscreenImage}
        sources={
          currentQuestion?.questionImage ? [currentQuestion.questionImage] : []
        }
      />
      <Modal
        isOpen={isOpen}
        containerProps={{
          width: { xs: "100%", sm: "70vw" },
          maxHeight: "100vh",
          style: {
            overflowY: "scroll",
          },
        }}
      >
        <>
          {step === 0 && (
            <Box
              py="1.5rem"
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="1rem" mb="1.5rem">
                {challenge?.title}
              </Text>

              <Card
                variant="outlined"
                style={{
                  marginBottom: "1rem",
                  borderRadius: "0.5rem",
                  border: "none",
                  margin: "1rem",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Box
                    display="flex"
                    py="0.5rem"
                    px="1rem"
                    sx={{
                      border: `1px solid ${Theme.colors.feedback.negative}`,
                      borderRadius: "0.5rem",
                    }}
                  >
                    <Box sx={{ display: "flex" }} alignItems="center">
                      <InfoIcon
                        size={20}
                        color={Theme.colors.feedback.negative}
                      />

                      <HighlightedText
                        fontWeight={400}
                        hightlightWeight={400}
                        ml={1}
                        text={` Você terá: {{20 minutos}} para completar esse desafio. Clique em {{Iniciar}} quando estiver pronto.`}
                        color={Theme.colors.text.tertiary}
                        highlightColor={Theme.colors.feedback.negative}
                      />
                    </Box>
                  </Box>

                  <Card
                    variant="outlined"
                    style={{ padding: "1rem", borderRadius: "0.5rem" }}
                  >
                    <Text align="center">{challenge?.description}</Text>
                  </Card>
                </div>
              </Card>

              <Box
                alignItems="center"
                justifyContent="center"
                py="0.5rem"
                position="relative"
              >
                {currentQuestion?.questionImage && (
                  <MediaTutorialWrapper onClick={toggleFullscreen}>
                    <TutorialMedia src={currentQuestion?.questionImage} />
                    <FullscreenButton />
                  </MediaTutorialWrapper>
                )}
              </Box>

              <Box
                display="flex"
                width={"100%"}
                alignItems="center"
                mt="1.5rem"
                flexDirection={{ xs: "column-reverse", sm: "row" }}
                gap={{ xs: "0.5rem", sm: "0rem" }}
                justifyContent={{ xs: "space-evenly" }}
              >
                <Button
                  variant="text"
                  fullWidth={isMobile}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "8rem",
                    },
                    padding: "0.3rem",
                    color: Theme.colors.feedback.negative,
                  }}
                  onClick={onClose}
                  color="error"
                >
                  Sair do case
                </Button>

                <Spacer width="1rem" />
                <Button
                  fullWidth={isMobile}
                  sx={{
                    width: {
                      xs: "90%",
                      sm: "8rem",
                    },
                  }}
                  style={{ padding: "0.5rem" }}
                  onClick={() => {
                    setStep(1);
                    const time = new Date();
                    time.setMinutes(time.getMinutes() + 20);
                    // time.setSeconds(time.getSeconds() + 10);
                    timeLeftRef.current = time;
                    if (!isProgressCase) {
                      setIsProgressCase(true);
                      restart(timeLeftRef.current);
                    }
                  }}
                >
                  Iniciar Case
                </Button>
              </Box>
            </Box>
          )}

          {step === 1 && (
            <Box
              pt="1.5rem"
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="1rem" mb="1.5rem">
                {challenge?.title}
              </Text>

              <Box
                sx={{ p: 2 }}
                display="flex"
                justifyContent="center"
                border={"1px solid #E0E0E0"}
                borderRadius={4}
                mb={1}
              >
                <TimerOutlinedIcon
                  sx={{ color: Theme.colors.brand.secondary }}
                />
                <Box ml={1} display="flex" alignItems="center">
                  Tempo Restante:{" "}
                  <Text color={Theme.colors.brand.secondary} ml={1}>
                    {minutes}m {seconds}s{" "}
                  </Text>
                </Box>
              </Box>
              <Box width="90%">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    maxWidth="35rem"
                    width="100%"
                    border={"1px solid #E0E0E0"}
                    px={2}
                    py={0.5}
                    sx={{
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                  >
                    <Text color={Theme.colors.brand.secondary}>
                      {currentQuestion?.title}
                    </Text>
                  </Box>

                  <Box width="100%" display={"flex"}>
                    {currentQuestion?.questionImage && (
                      <MediaTutorialWrapper
                        onClick={toggleFullscreen}
                        sx={{ width: "100%", height: "auto" }}
                      >
                        <TutorialMedia
                          width={"100%"}
                          style={{
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                          src={currentQuestion?.questionImage}
                        />
                        <FullscreenButton />
                      </MediaTutorialWrapper>
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="start">
                  <Text>PERGUNTA:</Text>
                </Box>

                <Box display="flex" pt="1rem" justifyContent="start">
                  <Text fontWeight={"bold"}>{currentQuestion?.question}</Text>
                </Box>

                <Text color="#515961" fontWeight={300} mt="1rem">
                  Opções de resposta:
                </Text>

                {currentAlternatives.map(({ id, answer }) => (
                  <AlternativeWrapper
                    isSelected={getIsSelected(id)}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }

                      setCurrentQuestionAnswer(id);
                    }}
                  >
                    <AlternativeBullet isSelected={getIsSelected(id)} />
                    <Text fontSize={isMobile ? "0.7rem" : "0.8rem"}>
                      {answer}
                    </Text>
                  </AlternativeWrapper>
                ))}

                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent={{ xs: "space-between", sm: "space-between" }}
                  my="2.5rem"
                  gap={{ xs: "1rem", sm: "1rem" }}
                >
                  <Box width={{ xs: "100%", sm: "60%" }}>
                    <ProgressBar
                      maxCompleted={challenge?.questions?.length ?? 1}
                      completed={answers.length}
                      customLabel={`${answers.length} / ${
                        challenge?.questions?.length ?? 1
                      }`}
                      customLabelStyles={{
                        fontFamily: "Roboto",
                        fontSize: "0.8rem",
                        background: Theme.colors.brand.secondary,
                      }}
                      bgColor={"#005EB8"}
                      baseBgColor={"#E0E0E0"}
                    />
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    mb="0.5rem"
                    width={{ xs: "100%", sm: "8rem" }}
                  >
                    <Button
                      fullWidth={false}
                      style={{ padding: "0.3rem", width: "100%" }}
                      onClick={
                        isLastQuestion
                          ? async () => {
                              pause();
                              await onSubmit();
                            }
                          : () => onNextQuestion()
                      }
                      disabled={!currentAnswer || isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size="1.5rem"
                          style={{ color: "white" }}
                        />
                      ) : (
                        <>{isLastQuestion ? "Finalizar Case" : "Avançar"}</>
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      </Modal>

      <Dialog
        isOpen={isOpenLeaveDialog}
        icon={<InfoIcon color={Theme.colors.feedback.negative} />}
        text="Atenção! Você está saindo do case. Ao confirmar, todo o seu progresso atual será perdido."
        confirmButtonText="Continuar no Case"
        onConfirm={toggleLeaveDialog}
        closeButtonText="Sair do Case"
        onClose={onLeaveQuiz}
      />
    </>
  );
};

export default QuizQuestionsDialog;
