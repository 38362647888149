import Spacer from "@/components/Spacer";

import UserInfoHeader from "@/components/UserInfoHeader";
import InfoCard from "@/components/InfoCard";
import CurrentStageCard from "./components/CurrentStageCard";
import { useDashboard } from "./useDashboard";

import { getStorageImageUrl } from "@/utils/get-storage-image-url";
import { useGetCompletedChallenges } from "@/hooks/useGetCompletedChallenges";
import { useGetCompletedCourses } from "@/hooks/useGetCompletedCourses";
import { realityInfo } from "@/utils/realityInfo";

function Dashboard() {
  const { completedChallengesCount } = useGetCompletedChallenges();
  const { completedCoursesCount } = useGetCompletedCourses();
  const { handleNavigateToCourse, handleNavigateToChallenges } = useDashboard();

  return (
    <div>
      <CurrentStageCard />

      <UserInfoHeader />

      <Spacer height="2rem" />
      <InfoCard
        imageSrc={getStorageImageUrl("dashboard_challenges.png")}
        title="Desafios"
        description={realityInfo.dashboardChallengesText}
        buttonCta="Ir para Desafios"
        completedCounter={completedChallengesCount}
        onClick={handleNavigateToChallenges}
      />

      <Spacer height="2rem" />
      <InfoCard
        imageSrc={getStorageImageUrl("dashboard_courses.png")}
        title="Cursos"
        description="Adquira conhecimento e aumente sua pontuação através dos cursos e provas. Quanto mais acertos, mais pontos você conquista!"
        buttonCta="Ir para Cursos"
        completedCounter={completedCoursesCount}
        onClick={handleNavigateToCourse}
      />
    </div>
  );
}

export default Dashboard;
