import { memo } from "react";
import { Box } from "@mui/material";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import Divider from "@/components/Divider";
import { QuestionWrapper } from "../../styles";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { QuizPointsDialogProps } from "./props.type";

const QuizPointsDialog = ({
  isOpen,
  onClose,
  totalCorrect,
  totalWrong,
  challenge,
}: QuizPointsDialogProps) => {
  return (
    <Modal
      isOpen={isOpen}
      containerProps={{
        width: { xs: "100%", sm: "50vw" },
      }}
    >
      <Box
        py="1.5rem"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text fontSize="1rem" mb="1.5rem">
          {challenge?.title}
        </Text>

        <Box width="90%">
          <QuestionWrapper>
            <Box display="flex" p="1rem" justifyContent="center">
              <Text color={Theme.colors.brand.secondary}>
                PARABÉNS! VOCÊ COMPLETOU O CASE.
              </Text>
            </Box>

            <Divider />

            <Box
              display="flex"
              flexDirection="column"
              p="1rem"
              alignItems="center"
              justifyContent="center"
            >
              <Text color={Theme.colors.text.secondary} mt="2rem" mb="1.5rem">
                Pontos Adquiridos:
              </Text>

              <QuestionWrapper
                borderColor={Theme.colors.brand.secondary}
                p="1rem 1.5rem"
                flexDirection="row"
              >
                <Box>
                  <Text fontSize="2.7rem" color={Theme.colors.brand.secondary}>
                    {challenge.points.earnedPoints} pts
                  </Text>
                </Box>

                <Divider vertical margin="0rem 1rem" color="#93999F" />

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color={Theme.colors.text.secondary} fontSize="0.4rem">
                    Pontuação Máxima:
                  </Text>
                  <Text
                    color={Theme.colors.text.secondary}
                    fontSize="1.1rem"
                    fontWeight={300}
                  >
                    {challenge.points.maximumPoints} pts
                  </Text>
                </Box>
              </QuestionWrapper>
              <Text color={Theme.colors.text.secondary} mt="1.5rem">
                Respostas Certas: <Text color="green">{totalCorrect}</Text> |
                Respostas Erradas: <Text color="red">{totalWrong}</Text>
              </Text>
            </Box>
          </QuestionWrapper>

          <Box display="flex" width="100%" justifyContent="end" mt="2rem">
            <Button
              fullWidth={false}
              style={{ padding: "0.3rem", width: "8rem" }}
              onClick={onClose}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(QuizPointsDialog);
