import { MobileOnly } from "@/shared/responsive-layouts";
import { getStorageImageUrl } from "@/utils/get-storage-image-url";
import { mobileBreakpoint } from "@/utils/mobile-breakpoint";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light};

  max-height: 4rem;
  padding: 1.5rem;

  align-items: center;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1.5rem 1rem;
  }
`;

export const RightContentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
`;

export const UserImage = styled.img`
  width: 2rem;
  height: 2rem;

  object-fit: cover;
  border-radius: 10rem;
`;

export const UserInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const MobileLogoWrapper = styled(MobileOnly)`
  flex: 1;
`;

export const Logo = styled.img.attrs({
  src: getStorageImageUrl("logo_sidebar.svg"),
})`
  max-width: 5rem;
  object-fit: contain;
`;
