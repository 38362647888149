import { UserChallengeStatus } from "@/services/challenges/types/challenges.enums";
import { ChallengeType } from "@/services/challenges/types/challenges.types";
import Theme from "@/utils/theme";
import { useMemo, useState } from "react";

export const useCaseChallengeSubmission = () => {
  const [challenge, setChallenge] = useState<
    ChallengeType & { quizzes?: ChallengeType[] }
  >();

  const [isOpenQuizId, setIsOpenQuizId] = useState<number>();

  const challengeStatus = useMemo(() => {
    if (challenge?.status === UserChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Concluído",
      };
    }

    if (challenge?.status === UserChallengeStatus.REJECTED) {
      return {
        color: Theme.colors.feedback.negative,
        text: "Reprovado",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Pendente",
    };
  }, [challenge?.status]);

  const getQuizStatus = (quiz: ChallengeType) => {
    if (quiz?.status === UserChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Concluído",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Pendente",
    };
  };

  const quizzes = useMemo(() => {
    return (
      challenge?.quizzes?.map((q) => ({ ...q, status: getQuizStatus(q) })) ?? []
    );
  }, [challenge]);

  const currentQuiz = useMemo(() => {
    if (!isOpenQuizId || !challenge?.quizzes) return undefined;

    return challenge?.quizzes.find((quiz) => quiz.id === isOpenQuizId);
  }, [isOpenQuizId, challenge]);

  return {
    challenge,
    setChallenge,
    challengeStatus,
    quizzes,

    currentQuiz,
    setIsOpenQuizId,
  };
};
