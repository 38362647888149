import { StateResponse, getStates } from "@/utils/get-localities";
import { useEffect, useMemo, useState } from "react";
import { CustomTextFieldProps } from "../TextField/props.type";

export const useStateSelect = (props: CustomTextFieldProps) => {
  const [states, setStates] = useState<StateResponse[]>([]);

  const optionValue = useMemo(
    () => states.find((s) => s.sigla === props.value),
    [states, props.value]
  );

  useEffect(() => {
    (async () => {
      const res = await getStates();
      setStates(res);
    })();
  }, []);

  return { states, optionValue };
};
