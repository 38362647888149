import { Box, InputAdornment } from "@mui/material";
import { UserImage } from "./styles";
import TextField from "@/components/TextField";
import Spacer from "@/components/Spacer";
import PhoneInput from "@/components/PhoneInput";
import GenderSelect from "@/components/GenderSelect";
import StateSelect from "@/components/StateSelect";
import CitySelect from "@/components/CitySelect";
import Button from "@/components/Button";
import { useProfile } from "./useProfile";
import Dialog from "@/components/Dialog";
import { Gender } from "@/services/auth/types/auth.types";
import CardWithShadow from "@/components/CardWithShadow";

import avatar from "/assets/avatar.png";
import { ProfileImageInput } from "./components/ProfileImageInput";
import DateInput from "@/components/DateInput";
import { useStages } from "@/context/StagesContext";
import CpfInput from "@/components/CpfInput";
import { useUser } from "@/context/UserContext";
import CircleCheck from "@/ui/icons/CircleCheck";
import { Tooltip } from "@/components/Tooltip";
import InfoIcon from "@/ui/icons/InfoIcon";

export default function Profile() {
  const {
    imageUrl,
    setImageUrl,
    name,
    setName,
    nameError,
    email,
    setEmail,
    phoneNumber,
    phoneNumberError,

    birthDate,
    setBirthDate,
    birthDateError,
    state,
    setState,
    region,
    city,
    setCity,
    instagramAccount,
    setInstagramAccount,
    gender,
    setGender,
    address,
    setAddress,
    numberAddress,
    setNumberAddress,
    complement,
    setComplement,
    cep,
    setCep,
    shirtSize,
    setShirtSize,
    cpf,
    setCpf,
    phoneNumberResponsible,
    setPhoneNumberResponsible,
    phoneNumberResponsibleError,
    shoeSize,
    setShoeSize,
    pantsSize,
    setPantsSize,
    isLoading,
    hasChanges,
    onPressCancel,
    onPressSave,
    showChangesDialog,
    toggleChangesDialog,
    showConfirmChangesDialog,
    toggleConfirmDialog,
    showChangesSavedDialog,
    toggleChangesSavedDialog,
    onSaveChanges,
    onCancelChanges,

    isOpenInstagramTooltip,
    toggleTooltip,
  } = useProfile();

  const { userInfo } = useUser();
  const { currentStageNumber, isValidStage } = useStages();

  return (
    <CardWithShadow p="1.5rem">
      <Box
        display="flex"
        flex={1}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          position="relative"
          width="fit-content"
          height="fit-content"
          alignSelf={{ xs: "center", sm: "start" }}
        >
          <ProfileImageInput
            setImageUrl={setImageUrl}
            disabled={currentStageNumber > 1 || !isValidStage}
          />
          <UserImage src={imageUrl || avatar} />
        </Box>

        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          ml={{ xs: "0px", sm: "1rem" }}
        >
          <Box
            display="flex"
            flex={1}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <TextField
              label="Nome completo"
              value={name}
              setValue={setName}
              disabled={currentStageNumber > 1 || !isValidStage}
              ContainerProps={{
                mr: { xs: "0px", sm: "1rem" },
                mt: { xs: "1.8rem", sm: "0px" },
                flex: 1,
              }}
              error={!!nameError}
              helperText={nameError}
            />

            <TextField
              label="E-mail"
              value={email}
              setValue={setEmail}
              disabled={currentStageNumber > 1 || !isValidStage}
              ContainerProps={{
                mr: { xs: "0px", sm: "1rem" },
                mt: { xs: "1.8rem", sm: "0px" },
                flex: 1,
              }}
            />

            <Box
              display="flex"
              flex={1}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <PhoneInput
                label="Telefone"
                value={phoneNumber}
                disabled
                ContainerProps={{
                  flex: 1,
                  mt: { xs: "1.8rem", sm: "0px" },
                  mr: { xs: "0px", sm: "1rem" },
                }}
                error={!!phoneNumberError}
                errorText={phoneNumberError}
              />

              <DateInput
                label="Data Nascimento"
                value={birthDate}
                setValue={setBirthDate}
                disabled={currentStageNumber > 1 || !isValidStage}
                ContainerProps={{ flex: 1, mt: { xs: "1.8rem", sm: "0px" } }}
                error={!!birthDateError}
                errorText={birthDateError}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flex={1}
            mt={{ xs: "0px", sm: "1.5rem" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box
              flex={1}
              mr={{ xs: "0px", sm: "1rem" }}
              mt={{ xs: "1.8rem", sm: "0px" }}
            >
              <StateSelect
                value={state}
                setValue={setState}
                disabled={currentStageNumber > 1 || !isValidStage}
                error={!state}
                helperText={!state ? "Campo obrigatório" : ""}
              />
            </Box>

            <Box
              display="flex"
              flex={1}
              mt={{ xs: "1.8rem", sm: "0px" }}
              mr={{ xs: "0px", sm: "1rem" }}
            >
              <Box flex={1}>
                <CitySelect
                  state={state}
                  value={city}
                  setValue={setCity}
                  disabled={currentStageNumber > 1 || !isValidStage}
                  error={!city}
                  helperText={!city ? "Escolha uma cidade" : ""}
                />
              </Box>

              <Spacer width="1rem" />
              <TextField
                label="Região"
                value={region}
                disabled
                ContainerProps={{ flex: 1 }}
              />
            </Box>

            <Box display="flex" flex={1} mt={{ xs: "1.8rem", sm: "0px" }}>
              <TextField
                label="Instagram (com o @)"
                value={instagramAccount}
                setValue={setInstagramAccount}
                disabled={
                  (currentStageNumber > 1 || !isValidStage) &&
                  !!userInfo?.instagramAccount
                }
                ContainerProps={{ flex: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        isOpen={isOpenInstagramTooltip}
                        onClose={toggleTooltip}
                        placement="top-end"
                        title="Caso não possua a rede social solicitada, informe que não tem. Não se preocupe caso não tenha, apenas queremos conhecer melhor você."
                      >
                        <Box
                          display="flex"
                          style={{ cursor: "pointer" }}
                          onClick={toggleTooltip}
                        >
                          <InfoIcon size={20} />
                        </Box>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={!instagramAccount && currentStageNumber > 1}
                helperText={
                  !instagramAccount && currentStageNumber > 1
                    ? "Campo obrigatório"
                    : ""
                }
              />

              <Spacer width="1rem" />
              <GenderSelect
                value={gender}
                onChange={(e) => setGender(e.target.value as Gender)}
                disabled={currentStageNumber > 1 || !isValidStage}
                fullWidth
                ContainerProps={{ flex: 1 }}
                error={!gender}
                helperText={!gender ? "Campo obrigatório" : ""}
              />
            </Box>
          </Box>

          <Box
            display={currentStageNumber > 1 ? "flex" : "none"}
            flex={1}
            mt={{ xs: "0px", sm: "1.5rem" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <TextField
              label="Endereço"
              value={address}
              setValue={setAddress}
              disabled={!!userInfo?.address}
              ContainerProps={{
                mr: { xs: "0px", sm: "1rem" },
                mt: { xs: "1.8rem", sm: "0px" },
                flex: 1,
              }}
              error={!address}
              helperText={!address ? "Campo obrigatório" : ""}
            />

            <Box
              display="flex"
              flex={1}
              mt={{ xs: "1.8rem", sm: "0px" }}
              mr={{ xs: "0px", sm: "1rem" }}
            >
              <TextField
                label="Número"
                value={numberAddress}
                setValue={setNumberAddress}
                disabled={!!userInfo?.numberAddress}
                ContainerProps={{ flex: 1 }}
                onlyNumbers
                error={!numberAddress}
                helperText={!numberAddress ? "Campo obrigatório" : ""}
              />

              <Spacer width="1rem" />
              <TextField
                label="Complemento"
                value={complement}
                setValue={setComplement}
                disabled={!!userInfo?.complement}
                ContainerProps={{ flex: 1 }}
                error={!complement}
                helperText={!complement ? "Campo obrigatório" : ""}
              />
            </Box>

            <Box display="flex" flex={1} mt={{ xs: "1.8rem", sm: "0px" }}>
              <TextField
                label="Cep"
                value={cep}
                setValue={setCep}
                disabled={!!userInfo?.cep}
                ContainerProps={{ flex: 1 }}
                onlyNumbers
                error={!cep}
                helperText={!cep ? "Campo obrigatório" : ""}
              />

              <Spacer width="1rem" />
              <TextField
                label="Tamanho Camisa"
                value={shirtSize}
                setValue={setShirtSize}
                disabled={!!userInfo?.shirtSize}
                ContainerProps={{ flex: 1 }}
                error={!shirtSize}
                helperText={!shirtSize ? "Campo obrigatório" : ""}
              />
            </Box>
          </Box>

          <Box
            display={currentStageNumber > 2 ? "flex" : "none"}
            flex={1}
            mt={{ xs: "0px", sm: "1.5rem" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box
              display="flex"
              flex={1}
              mt={{ xs: "1.8rem", sm: "0px" }}
              mr={{ xs: "0px", sm: "1rem" }}
            >
              <CpfInput
                label="CPF"
                value={cpf}
                setValue={setCpf}
                disabled={!!userInfo?.cpf}
                ContainerProps={{ flex: 1 }}
                error={!cpf}
                errorText={!cpf ? "Campo obrigatório" : ""}
              />

              <Spacer width="1rem" />
              <PhoneInput
                label="Contato (Responsável)"
                value={phoneNumberResponsible}
                setValue={setPhoneNumberResponsible}
                ContainerProps={{ flex: 1 }}
                error={!!phoneNumberResponsibleError}
                errorText={phoneNumberResponsibleError}
                disabled={
                  !!userInfo?.phoneNumberResponsible &&
                  !phoneNumberResponsibleError &&
                  phoneNumberResponsible === userInfo?.phoneNumberResponsible
                }
              />
            </Box>

            <TextField
              label="Tamanho Calçado"
              value={shoeSize}
              setValue={setShoeSize}
              disabled={!!userInfo?.shoeSize}
              ContainerProps={{
                mr: { xs: "0px", sm: "1rem" },
                mt: { xs: "1.8rem", sm: "0px" },
                flex: 1,
              }}
              error={!shoeSize}
              helperText={!shoeSize ? "Campo obrigatório" : ""}
            />

            <TextField
              label="Tamanho Calça"
              value={pantsSize}
              setValue={setPantsSize}
              disabled={!!userInfo?.pantsSize}
              ContainerProps={{
                mr: { xs: "0px" },
                mt: { xs: "1.8rem", sm: "0px" },
                flex: 1,
              }}
              error={!pantsSize}
              helperText={!pantsSize ? "Campo obrigatório" : ""}
            />
          </Box>
        </Box>
      </Box>

      <Box mt="1.5rem" justifyContent="end" display="flex">
        <>
          <Button
            onClick={onPressCancel}
            size="small"
            variant="outlined"
            fullWidth={false}
            disabled={!hasChanges}
            style={{
              minWidth: "10%",
              padding: "0.5rem",
            }}
          >
            Cancelar
          </Button>

          <Button
            onClick={onPressSave}
            disabled={!hasChanges}
            size="small"
            fullWidth={false}
            style={{
              minWidth: "10%",
              padding: "0.5rem",
              marginLeft: "1rem",
            }}
          >
            Salvar
          </Button>
        </>

        <Dialog
          isOpen={showConfirmChangesDialog}
          text={`Atenção! Deseja realmente prosseguir com as alterações?`}
          onConfirm={onSaveChanges}
          onClose={toggleConfirmDialog}
          isLoading={isLoading}
          confirmButtonText={"Salvar Alterações"}
        />

        <Dialog
          isOpen={showChangesDialog}
          text={`Atenção! Ao sair sem salvar, todas as modificações serão perdidas.\nDeseja descartas as alterações?`}
          onConfirm={onCancelChanges}
          confirmButtonText="Confirmar"
          onClose={toggleChangesDialog}
        />

        <Dialog
          isOpen={showChangesSavedDialog}
          icon={<CircleCheck />}
          text="Alterações salvas com sucesso!"
          onConfirm={toggleChangesSavedDialog}
        />
      </Box>
    </CardWithShadow>
  );
}
