import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { useMediaChallengeSubmission } from "./useMediaChallengeSubmission";
import ShapedAccordion from "@/components/ShapedAccordion";
import { Box, CircularProgress, Stack } from "@mui/material";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import { DashedContainer, TaskImage, TaskVideo } from "./styles";
import IconButton from "@/components/IconButton";
import {
  MediaType,
  TaskChallengeStatus,
} from "@/services/challenges/types/challenges.enums";
import Dialog from "@/components/Dialog";
import ChallengeSubmissionWrapper from "@/layout/ChallengeSubmissionWrapper";
import { TaskVideoInput } from "../TaskVideoInput";
import { TaskImageInput } from "../TaskImageInput";
import MediaDetailsDialog from "../MediaDetailsDialog";
import { useStages } from "@/context/StagesContext";
import DeleteIcon from "@/ui/icons/DeleteIcon";
import InfoIcon from "@/ui/icons/InfoIcon";
import Chip from "@/components/Chip";
import HighlightedText from "@/components/HighlightedText";
import { format } from "date-fns";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ChallengeTooltip } from "../ChallengeTooltip";

function MediaChallengeSubmission() {
  const { isValidStage, currentStage } = useStages();
  const {
    challenge,
    setChallenge,
    getTaskStatus,
    defaultOpenIndex,

    openMediaDetailsId,
    toggleMediaDetails,

    showHasChangesDialog,
    toggleHasChangesDialog,

    accordionKey,
    accordionIndexToClose,
    taskMedias,
    setTaskMedia,
    removeTaskMedia,
    getMediaStatus,
    taskInputs,
    hideTooltip,
    getShowTooltip,
    getDetailsButton,

    showRequiredInputDialog,
    toggleRequiredInputDialog,
    deleteTaskId,
    toggleDeleteTaskDialog,

    updateTaskInputValue,
    onSubmitTask,
    loadingTaskId,

    onClickBack,
    navigateToChallenges,
    onClickUploadArea,
    showHasDeleteDialog,
    toggleHasDeleteDialog,
  } = useMediaChallengeSubmission();

  return (
    <ChallengeSubmissionWrapper
      onLoadChallenge={setChallenge}
      onClickBackOverwrite={onClickBack}
    >
      <ShapedAccordion
        defaultIndexesKey={accordionKey}
        indexToClose={accordionIndexToClose}
        summaryStyle={{ backgroundColor: Theme.colors.brand.secondary }}
        items={challenge?.tasks?.map((task, index) => {
          const status = getTaskStatus(task);
          const mediaUrl = taskMedias[task.id];
          const mediaStatus = getMediaStatus(task);
          const isEditable =
            (task.status == TaskChallengeStatus.PENDING ||
              task.status == TaskChallengeStatus.REJECTED) &&
            isValidStage &&
            currentStage?.id === challenge.stageId;

          const shadowColor =
            task.status == TaskChallengeStatus.REJECTED
              ? Theme.colors.feedback.negative
              : task.status == TaskChallengeStatus.APPROVED ||
                task.status == TaskChallengeStatus.FINISHED
              ? Theme.colors.feedback.positive
              : Theme.colors.brand.primary;
          const detailsButton = getDetailsButton(task);

          return {
            defaultExpanded: index === defaultOpenIndex,
            summary: (
              <Box display="flex" alignItems="center">
                <Text color={Theme.colors.neutral.light}>{task.title}</Text>
                <Text color={Theme.colors.neutral.mid}> | </Text>
                <Chip label={status.text} color={status.color} />
              </Box>
            ),
            details: (
              <Box
                display="flex"
                flexDirection="column"
                p="1.5rem"
                pt={0}
                flex={1}
              >
                {task.status === TaskChallengeStatus.REJECTED ? (
                  <Stack
                    py={1}
                    px={2}
                    flexDirection="row"
                    alignItems="center"
                    border={`1px solid ${Theme.colors.feedback.negative}`}
                    borderRadius="0.5rem"
                    mt="1rem"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: Theme.colors.feedback.negative,
                        width: "2rem",
                        height: "2rem",
                      }}
                    />

                    <Spacer width="1rem" />
                    {currentStage?.endDate && (
                      <HighlightedText
                        color={Theme.colors.text.tertiary}
                        text={`A foto enviada foi: {{Reprovada}} | Visualize o motivo da reprovação clicando no ícone informativo. Em seguida, exclua a imagem reprovada e faça um novo envio. Envio até: {{${format(
                          currentStage?.endDate,
                          "dd/MM/yyy"
                        )}}}.`}
                        highlightColor={Theme.colors.feedback.negative}
                        hightlightWeight={900}
                      />
                    )}
                  </Stack>
                ) : null}

                <Box
                  display="flex"
                  flexDirection={
                    task.status === TaskChallengeStatus.REJECTED
                      ? "column-reverse"
                      : "column"
                  }
                >
                  {((isEditable && !mediaUrl) ||
                    task.status === TaskChallengeStatus.REJECTED) && (
                    <Box mt="1rem" onClick={onClickUploadArea(task.status)}>
                      {task.mediaType === MediaType.VIDEO ? (
                        <TaskVideoInput
                          disabled={
                            task.status !== TaskChallengeStatus.PENDING ||
                            !!mediaUrl
                          }
                          setVideoUrl={(url) => setTaskMedia(task, url)}
                          taskId={task.id}
                          maxSeconds={task.maxSeconds}
                          showTooltip={getShowTooltip(task.id, "file-input")}
                          onCloseTooltip={() =>
                            hideTooltip(task.id, "file-input")
                          }
                        />
                      ) : (
                        <TaskImageInput
                          disabled={
                            task.status !== TaskChallengeStatus.PENDING ||
                            !!mediaUrl
                          }
                          setImageUrl={(url) => setTaskMedia(task, url)}
                          taskId={task.id}
                          showTooltip={getShowTooltip(task.id, "file-input")}
                          onCloseTooltip={() =>
                            hideTooltip(task.id, "file-input")
                          }
                        />
                      )}
                    </Box>
                  )}

                  <DashedContainer
                    mt="1rem"
                    display={!mediaUrl ? "none" : "flex"}
                  >
                    <Box display="flex" alignItems="center">
                      {task.mediaType === MediaType.VIDEO ? (
                        <TaskVideo src={mediaUrl} />
                      ) : (
                        <TaskImage src={mediaUrl} />
                      )}

                      {/* TODO: add file name? */}
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        ml="1rem"
                      >
                        <Text>
                          {task.mediaType === MediaType.VIDEO
                            ? "video.mp4"
                            : "image.jpg"}
                        </Text>
                        <Text
                          color={Theme.colors.neutral.mid}
                          display={{ xs: "none", sm: "block" }}
                          mx="0.5rem"
                        >
                          |
                        </Text>
                        <Text color={mediaStatus.color}>
                          {mediaStatus.text}
                        </Text>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box position="relative">
                        <ChallengeTooltip
                          isOpen={getShowTooltip(task.id, "details-button")}
                          onClose={() => hideTooltip(task.id, "details-button")}
                          placement="top"
                          title="Agora é a sua vez de nos contar sobre a foto escolhida. Clique no botão para abrir o campo descritivo, salve a descrição e depois clique em “Enviar” para enviar a foto e o comentário."
                        />

                        <IconButton
                          icon={detailsButton.icon}
                          color={detailsButton.color}
                          onClick={() => toggleMediaDetails(task.id)}
                        />
                      </Box>

                      <Spacer width="0.5rem" />

                      <IconButton
                        disabled={!isEditable}
                        icon="delete"
                        color={Theme.colors.feedback.negative}
                        onClick={() => toggleDeleteTaskDialog(task.id)}
                      />
                    </Box>
                  </DashedContainer>
                </Box>

                <Box display="flex" justifyContent="end" mt="1rem">
                  <Button
                    fullWidth={false}
                    disabled={
                      !mediaUrl || task.status !== TaskChallengeStatus.PENDING
                    }
                    onClick={() => {
                      onSubmitTask(task.id, mediaUrl);
                    }}
                    style={{ padding: "0.2rem 2rem" }}
                  >
                    {loadingTaskId === task.id ? (
                      <CircularProgress
                        style={{ color: "white" }}
                        size="1.5rem"
                      />
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </Box>

                <MediaDetailsDialog
                  isOpen={openMediaDetailsId === task.id}
                  variant={
                    task.mediaType === MediaType.VIDEO ? "video" : "photo"
                  }
                  mediaUrl={mediaUrl}
                  onClose={() => toggleMediaDetails(task.id)}
                  customInputs={task.customInputs ?? []}
                  taskInputs={taskInputs[task.id] ?? {}}
                  disableCustomInputs={
                    task.status !== TaskChallengeStatus.PENDING
                  }
                  setTaskInput={updateTaskInputValue(task.id)}
                  feedback={task.feedback}
                />

                <Dialog
                  icon={<DeleteIcon />}
                  iconSize={48}
                  isOpen={deleteTaskId === task.id}
                  text={`Tem certeza que deseja remover ${
                    task.mediaType === MediaType.VIDEO
                      ? "o vídeo inserido"
                      : "a foto inserida"
                  }?`}
                  confirmButtonText="Não Remover"
                  onConfirm={() => toggleDeleteTaskDialog(task.id)}
                  closeButtonText="Sim, Tenho Certeza"
                  onClose={() => removeTaskMedia(task)}
                />
              </Box>
            ),
            shadowColor,
          };
        })}
      />

      <Dialog
        isOpen={showRequiredInputDialog}
        text={`Atenção!\nÉ necessário que você deixe uma descrição sobre a imagem clicando no botão de detalhes.`}
        onConfirm={toggleRequiredInputDialog}
      />

      <Dialog
        isOpen={showHasChangesDialog}
        text={`Atenção!\nVocê está saindo dessa página com envio pendente. Caso concorde em sair, todas as alterações serão perdidas. Deseja realmente sair dessa página?`}
        confirmButtonText="Continuar nessa página"
        onConfirm={toggleHasChangesDialog}
        closeButtonText="Sair dessa página"
        onClose={navigateToChallenges}
      />

      <Dialog
        isOpen={showHasDeleteDialog}
        text={`Atenção\nÉ necessário que você exclua a imagem enviada anteriormente, antes de prosseguir com o envio da próxima.`}
        confirmButtonText="Entendi"
        onConfirm={toggleHasDeleteDialog}
        icon={<InfoIcon size={32} color={Theme.colors.feedback.negative} />}
      />
    </ChallengeSubmissionWrapper>

    
  );
}

export default MediaChallengeSubmission;
