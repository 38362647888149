import api from "../api";
import { UserInfo } from "./types/user.types";

export const getPhoneNumberAvailability = async (phone: string) => {
  const res = await api.get<{
    isAvailable: boolean;
    isPhoneNumberValidated: boolean;
    userId: number;
  }>(`users/availability/phone/${phone}`);

  return res.data;
};

export const setReseleCodeAvailability = async (
  userId: number,
  relaseCode: string
) => {
  const res = await api.post(`users/availability/set-relese-code/`, {
    userId: userId,
    relaseCode: relaseCode,
  });

  return res.data;
};

export const getEmailAvailability = async (email: string) => {
  const res = await api.get<{ isAvailable: boolean }>(
    `users/availability/email/${email}`
  );

  return res.data;
};

export const getUserInfo = async (): Promise<UserInfo | undefined> => {
  const res = await api.get<UserInfo>("users/me");

  return res.data;
};

export const updateUserInfo = async (
  info: Partial<UserInfo>
): Promise<void> => {
  return api.put("users/me", { ...info });
};

export const getUploadProfileImageUrl = async (): Promise<string> => {
  const res = await api.get("users/profile-image-link");

  return res.data;
};
