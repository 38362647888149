import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fullPhoneNumberRegex } from "@/utils/regex";
import { useLoader } from "@/context/LoaderContext";
import {
  ConfirmationResult,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  signInWithCustomToken,
} from "firebase/auth";
import { auth } from "@/firebase.config";
import { useCountdown } from "@/hooks/useCountdown";
import { getPhoneNumberAvailability } from "@/services/users";
import { sendWhatsappCode, validateWhatsappCode } from "@/services/auth";

interface CustomWindow extends Window {
  confirmationResult?: ConfirmationResult | null;
  recaptchaVerifier?: RecaptchaVerifier;
  recaptchaWidgetId?: number;
}

declare const window: CustomWindow;

// Comes from Google reCAPTCHA V3 script included in the HTML file
declare const grecaptcha: any;

export const useSignin = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const { value: smsTimerValue, restart: restartTimer } = useCountdown();

  const [phoneNumber, setPhoneNumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const [currentStep, setCurrentStep] = useState(1);

  const [showSubscriptionsEndedDialog, setShowSubscriptionsEndedDialog] =
    useState(false);
  const [showNoAccountDialog, setShowNoAccountDialog] = useState(false);
  const toggleSubscriptionsEndedDialog = () =>
    setShowSubscriptionsEndedDialog(!showSubscriptionsEndedDialog);

  const [isOpenChooseValidation, setIsOpenChooseValidation] = useState(false);
  const [validationType, setValidationType] = useState<"sms" | "whatsapp">();

  const rawPhoneNumber = useMemo(() => {
    if (!phoneNumber) return "";

    return "+55" + phoneNumber.replace(/[^\d]/g, "");
  }, [phoneNumber]);

  const phoneNumberError = useMemo(() => {
    if (rawPhoneNumber?.length === 0) return "";

    const isValid = fullPhoneNumberRegex.test(rawPhoneNumber);

    if (!isValid) {
      return "Número de telefone inválido.";
    }

    return "";
  }, [rawPhoneNumber]);

  const onClickSignIn = async () => {
    try {
      setIsLoading(true);
      if (!phoneNumber) {
        return toast("Preencha todos os campos", { type: "error" });
      }

      if (phoneNumberError) {
        return;
      }

      const { isAvailable } = await getPhoneNumberAvailability(rawPhoneNumber);
      if (isAvailable) {
        return setShowNoAccountDialog(true);
      }

      if (currentStep === 1) {
        return setIsOpenChooseValidation(true);
      }

      if (validationType === "sms") {
        await verifyCaptcha();
      } else {
        await validateWhatsapp();
      }
    } catch (err: any) {
      console.error("Error signing-in", err);
      if (err?.response?.status === 403) {
        toggleSubscriptionsEndedDialog();
      } else {
        toast("Erro ao fazer login. Verifique seu telefone", {
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClickSignUp = () => {
    navigate("/sign-up");
  };

  const resetReCaptcha = () => {
    if (
      typeof grecaptcha !== "undefined" &&
      typeof window.recaptchaWidgetId !== "undefined"
    ) {
      grecaptcha.reset(window.recaptchaWidgetId);
    }
  };

  const verifyCaptcha = async () => {
    try {
      loader.show("login");

      await window.confirmationResult?.confirm(otp);
      navigate("/dashboard");
    } catch (error) {
      toast("Código inválido ou inexistente", { type: "error" });
      console.error("Código inválido ou inexistente");
      resetReCaptcha();
    } finally {
      loader.hide("login");
    }
  };

  const validateWhatsapp = async () => {
    try {
      loader.show("verify_code");
      const { isExpired, isValid, accessToken } = await validateWhatsappCode(
        rawPhoneNumber,
        otp
      );

      if (isExpired) {
        toast("Código expirado!", { type: "error" });
        return false;
      }

      if (!isValid) {
        toast("Código inválido ou inexistente", { type: "error" });
        return false;
      }

      await signInWithCustomToken(auth, accessToken);
    } catch (err) {
      toast("Erro ao validar o código", { type: "error" });
      console.error("Erro ao validar o código", err);
    } finally {
      loader.hide("verify_code");
    }
  };

  const sendSignInSmsCode = async (disableNextStep = false) => {
    loader.show("sign_in");
    const appVerifier = window.recaptchaVerifier;

    if (!rawPhoneNumber.length) return;
    setValidationType("sms");

    signInWithPhoneNumber(auth, rawPhoneNumber, appVerifier!)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        if (!disableNextStep) setCurrentStep(2);
      })
      .catch((error) => {
        console.error("Erro ao enviar código", error);
        toast("Erro ao enviar código", { type: "error" });
        resetReCaptcha();
      })
      .finally(() => {
        loader.hide("sign_in");
        setIsOpenChooseValidation(false);
      });
  };

  const onSendWhatsappCode = async (disableNextStep = false) => {
    setValidationType("whatsapp");

    try {
      loader.show("send_whatsapp_code");
      await sendWhatsappCode(rawPhoneNumber);
      if (!disableNextStep) setCurrentStep(2);
    } catch (err) {
      console.error("Erro ao enviar código", err);
      toast("Erro ao enviar código", { type: "error" });
    } finally {
      setIsOpenChooseValidation(false);
      loader.hide("send_whatsapp_code");
    }
  };

  const resendCode = () => {
    restartTimer();
    if (validationType === "sms") {
      sendSignInSmsCode(true);
    } else {
      onSendWhatsappCode(true);
    }
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (_response: any) {
          sendSignInSmsCode();
        },
      }
    );
  }, []);

  useEffect(() => {
    resetReCaptcha();
  }, [rawPhoneNumber]);

  useEffect(() => {
    restartTimer();
  }, [currentStep]);

  return {
    currentStep,
    setCurrentStep,

    phoneNumber,
    setPhoneNumber,
    phoneNumberError,

    otp,
    setOtp,

    onClickSignIn,
    isLoading,
    onClickSignUp,
    isOpenChooseValidation,
    setIsOpenChooseValidation,

    showSubscriptionsEndedDialog,
    toggleSubscriptionsEndedDialog,

    smsTimerValue,
    sendSignInSmsCode,
    onSendWhatsappCode,

    showNoAccountDialog,
    setShowNoAccountDialog,
    resendCode,
  };
};
