import api from "../api";
import { ChallengeType, ChallengeSubmission } from "./types/challenges.types";

export const getQuizChallengeInfo = async (
  id: number
): Promise<ChallengeType | undefined> => {
  const res = await api.get<ChallengeType>(`quiz-challenges/${id}`);

  return res.data;
};

export const getCaseChallengeInfo = async (
  id: number
): Promise<ChallengeType | undefined> => {
  const res = await api.get<ChallengeType>(`case-challenges/${id}`);

  return res.data;
};

export const submitQuizChallenge = async ({
  id,
  answers,
}: ChallengeSubmission): Promise<void> => {
  return api.post(`quiz-challenges/${id}/submissions`, {
    answers,
  });
};
