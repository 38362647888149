import { realityId } from "./reality-id";

const DrywallTheme: ThemeType = {
  colors: {
    text: {
      primary: "#232629",
      secondary: "#93999F",
      tertiary: "#6A737C",
    },
    brand: {
      primary: "#FFEB00",
      secondary: "#005EB8",
    },
    button: {
      contained: {
        primary: {
          main: "#005EB8",
          text: "#FFFFFF",
        },
        secondary: {
          main: "#FFEB00",
          text: "#060500",
        },
      },
      outlined: { primary: "#005EB8" },
      text: "#005EB8",
    },
    icon: {
      default: "#005EB8",
    },
    sidebar: {
      icon: { active: "#FFEB00", default: "#005EB8" },
      text: { active: "#FFEB00", default: "#232629" },
    },
    toolbar: {
      text: "#232629",
      icon: "#005EB8",
    },
    background: {
      primary: "#F9FAFA",
      secondary: "#FFF",
      card: "#FFF",
      auth: "#FFF",
    },
    feedback: {
      positive: "#107048",
      negative: "#BE3E37",
      info: "#23489F",
      warning: "#EFBA2A",
    },
    neutral: {
      lightest: "#FFF",
      light: "#ECEDEE",
      mid: "#D0D4D7",
      mid_dark: "#A9B0B7",
      dark: "#0A0900",
      darkest: "#000",
    },
    footer: {
      background: {
        default: "#00000005",
        auth: "#004383",
      },
    },
    regulation: {
      brand_light: "#FFFDE6",
      brand_dark: "#6B6300",
    },
  },
};

const PedreiroTheme: ThemeType = {
  colors: {
    text: {
      primary: "#232629",
      secondary: "#93999F",
      tertiary: "#6A737C",
    },
    brand: {
      primary: "#FFEB00",
      secondary: "#FFEB00",
    },
    button: {
      contained: {
        primary: {
          main: "#FFEB00",
          text: "#060500",
        },
        secondary: {
          main: "#0A0900",
          text: "#FFEB00",
        },
      },
      outlined: { primary: "#B5A700" },
      text: "#060500",
    },
    icon: {
      default: "#060500",
    },
    sidebar: {
      icon: { active: "#060500", default: "#FFEB00" },
      text: { active: "#060500", default: "#FFFFFF" },
    },
    toolbar: {
      text: "#FFFFFF",
      icon: "#FFEB00",
    },
    background: {
      primary: "#E9E9E9",
      secondary: "#3B3A33",
      card: "#FFF",
      auth: "#3B3A33",
    },
    feedback: {
      positive: "#107048",
      negative: "#BE3E37",
      info: "#23489F",
      warning: "#EFBA2A",
    },
    neutral: {
      lightest: "#FFF",
      light: "#ECEDEE",
      mid: "#D0D4D7",
      mid_dark: "#A9B0B7",
      dark: "#0A0900",
      darkest: "#000",
    },
    footer: {
      background: {
        default: "#00000005",
        auth: "#5B5A54",
      },
    },
    regulation: {
      brand_light: "#FFFDE6",
      brand_dark: "#6B6300",
    },
  },
};

export type ThemeType = {
  colors: {
    text: {
      // TODO: change to light, mid dark
      primary: string;
      secondary: string;
      tertiary: string;
    };
    brand: {
      primary: string;
      secondary: string;
    };
    button: {
      contained: {
        primary: {
          main: string;
          text: string;
        };
        secondary: {
          main: string;
          text: string;
        };
      };
      outlined: { primary: string };
      text: string;
      // disabled
    };
    icon: {
      default: string;
      // disabled
    };
    sidebar: {
      icon: { active: string; default: string };
      text: { active: string; default: string };
    };
    toolbar: {
      text: string;
      icon: string;
    };
    background: {
      primary: string;
      secondary: string;
      card: string;
      auth: string;
    };
    feedback: {
      positive: string;
      negative: string;
      info: string;
      warning: string;
    };
    neutral: {
      lightest: string;
      light: string;
      mid: string;
      mid_dark: string;
      dark: string;
      darkest: string;
    };
    footer: {
      background: {
        default: string;
        auth: string;
      };
    };
    regulation?: {
      brand_light: string;
      brand_dark: string;
    };
  };
};

const Theme = realityId == 1 ? DrywallTheme : PedreiroTheme;

export default Theme;
