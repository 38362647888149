import { memo, useState } from "react";
import { Autocomplete } from "@mui/material";
import TextField from "../TextField";
import { CustomAutocompleteProps } from "./props.types";
import ChevronDown from "@/ui/icons/ChevronDown";

const CustomAutocomplete = ({
  label,
  value,
  disabled,
  setValue,
  options,
  getOptionLabel,
  disableClearable,
  onClear,
}: CustomAutocompleteProps) => {
  const [inputValue, setInputValue] = useState(value);

  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      disableClearable={disableClearable}
      popupIcon={<ChevronDown size={24} />}
      ListboxProps={{
        style: { maxHeight: "150px" },
        sx: {
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
            border: "2px solid #f0f0f0",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        },
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value ?? ""}
      onChange={(_, option, reason) => {
        if (option) {
          setValue?.(option);
        } else {
          setValue?.("");
        }

        if (reason === "clear") {
          onClear?.();
        }
      }}
      size="small"
      noOptionsText="Nenhuma opção encontrada"
      inputValue={inputValue ?? ""}
      onInputChange={(_, val) => setInputValue(val)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          // sx={{
          //   "& .MuiInputLabel-root": {
          //     color: Theme.colors.text.primary,
          //   },
          // }}
          inputProps={{
            ...params.inputProps,
            //avoid auto fill, may cause problems in some browsers
            //https://mui.com/material-ui/react-autocomplete/#autocomplete-autofill
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
};

export default memo(CustomAutocomplete);
