import Text from "@/components/Text";
import Spacer from "@/components/Spacer";
import { DialogProps } from "./props.type";
import { DialogContent, DialogTextWrapper } from "./styles";
import { Dialog as MUIDialog } from "@mui/material";
import Theme from "@/utils/theme";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@/components/Button";
import InfoIcon from "@/ui/icons/InfoIcon";
import reactStringReplace from "react-string-replace";

const Dialog = ({
  isOpen,
  icon,
  text,
  confirmButtonText = "Entendi",
  onConfirm,
  closeButtonText = "Cancelar",
  onClose,
  isLoading,
  content,
  footer,
  iconSize,
  variantButtonConfirm = "contained",
  maxWidth = "20rem",
}: DialogProps) => {
  return (
    <MUIDialog open={isOpen}>
      <DialogContent maxWidth={maxWidth}>
        {icon}
        {!icon && <InfoIcon size={iconSize} />}

        <DialogTextWrapper>
          <Text
            display={"inline-block"}
            fontSize="1rem"
            align="center"
            lineHeight="1.2rem"
          >
            {reactStringReplace(text, /\[b\]([\s\S]+)\[\/b\]/g, (match) => {
              return (
                <Text fontSize="1rem" fontWeight={"700"}>
                  {match}
                </Text>
              );
            })}
          </Text>
        </DialogTextWrapper>

        {content}

        <Button
          onClick={isLoading ? () => {} : onConfirm}
          disableRipple={isLoading}
          variant={variantButtonConfirm}
        >
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} size="1rem" />
          ) : (
            <Text
              color={
                variantButtonConfirm === "outlined"
                  ? Theme.colors.button.text
                  : Theme.colors.button.contained.primary.text
              }
              fontSize="1rem"
            >
              {confirmButtonText}
            </Text>
          )}
        </Button>

        {onClose && (
          <>
            <Spacer height="1.5rem" />
            <Button onClick={onClose} variant="outlined">
              <Text fontSize="1rem" color={Theme.colors.button.text}>
                {closeButtonText}
              </Text>
            </Button>
          </>
        )}

        <Spacer height="1.5rem" />
      </DialogContent>

      {footer}
    </MUIDialog>
  );
};

export default Dialog;
