export enum CourseStatus {
  PENDING = "Pending",
  FINISHED = "Finished",
  IN_PROGRESS = "In Progress",
}

export type Course = {
  title: string;
  thumbnailUrl: string;
  courseUrl: string;
  examPoints?: number;
  coursePoints?: number;
  status: CourseStatus;
  points: number;
};

export type CompletedCourses = { completedCourses: number };
