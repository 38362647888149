import { useStateSelect } from "./useStateSelect";
import { CustomTextFieldProps } from "../TextField/props.type";
import CustomAutocomplete from "../CustomAutocomplete";

const StateSelect = (props: CustomTextFieldProps) => {
  const { states, optionValue } = useStateSelect(props);

  return (
    <CustomAutocomplete
      label="Estado"
      disabled={props.disabled}
      options={states}
      getOptionLabel={(state) => state.nome ?? ""}
      value={optionValue}
      setValue={(val) => {
        props.setValue?.(val.sigla);
      }}
    />
  );
};

export default StateSelect;
