import { SignupStepperProps } from "./props.type";
import { MobileStepper, ThemeProvider, createTheme } from "@mui/material";
import { realityInfo } from "@/utils/realityInfo";
import Theme from "@/utils/theme";

const SignupStepper = ({ currentStep }: SignupStepperProps) => {
  const theme = createTheme({
    palette: {
      primary: { main: Theme.colors.brand.secondary },
    },
  });

  return (
    <>
      {realityInfo.showResaleCode && (
        <ThemeProvider theme={theme}>
          <MobileStepper
            steps={3}
            position="static"
            activeStep={currentStep}
            nextButton={<></>}
            backButton={<></>}
            style={{ marginLeft: "-0.5rem" }}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default SignupStepper;
