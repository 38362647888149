import Spacer from "@/components/Spacer";
import { Box } from "@mui/material";
import { useCourses } from "@/pages/Courses/useCourses";
import { useStages } from "@/context/StagesContext";
import CardWithShadow from "@/components/CardWithShadow";
import { useGetCompletedCourses } from "@/hooks/useGetCompletedCourses";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import GraduationIcon from "@/ui/icons/GraduationIcon";
import SectionDescription from "@/components/SectionDescription";
import CourseCard from "./components/CourseCard";

function Courses() {
  const { isValidStage, currentStageNumber } = useStages();
  const { completedCoursesCount, isLoading } = useGetCompletedCourses();
  const { courses, onGoToCourse } = useCourses(!isLoading);

  return (
    <div>
      <SectionDescription
        title="Complete os cursos"
        description="Mestre, através do botão {{'Iniciar curso'}} você {{acessará}} os cursos para conquistar mais {{pontos}}. Fique atento: os cursos devem {{ser assistidos}} até o final e o exame {{final}} deve ser realizado para {{aumentar}} a pontuação."
      />

      <CardWithShadow px="1.5rem" py="1rem">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="1rem"
        >
          <Text>Cursos Disponíveis</Text>

          <Box
            display="flex"
            p="0.5rem"
            border={`1px solid ${Theme.colors.neutral.light}`}
            borderRadius="0.5rem"
            alignItems="center"
          >
            <GraduationIcon size={20} />
            <Text color={Theme.colors.neutral.mid_dark} ml="0.3rem">
              Total de cursos concluídos:{" "}
            </Text>
            <Text>
              {completedCoursesCount}/{courses?.length}
            </Text>
          </Box>
        </Box>

        {courses?.map((item) => (
          <Box key={item.title}>
            <CourseCard
              imageSrc={item.thumbnailUrl}
              title={item.title}
              lockButton={!item.courseUrl}
              courseEnded={!isValidStage || currentStageNumber > 1}
              onClick={() => onGoToCourse(item.courseUrl)}
              course={item}
            />

            <Spacer height="2rem" />
          </Box>
        ))}
      </CardWithShadow>
    </div>
  );
}

export default Courses;
