import { useEffect, useMemo, useState } from "react";
import { ShapedAccordionProps } from "./props.type";

export const useShapedAccordion = ({
  items,
  fixedIndex,
  onChangeExpanded,
  indexToClose = -1,
  defaultIndexesKey,
}: Partial<ShapedAccordionProps>) => {
  const [userExpandedIndexes, setUserExpandedIndexes] = useState<{
    [index: number]: boolean | undefined;
  }>({});
  const [defaultExpandedIndexes, setDefaultExpandedIndexes] = useState<
    number[]
  >([]);
  const [currentDefaultsKey, setCurrentDefaultsKey] =
    useState(defaultIndexesKey);
  const [shouldCloseIndex, setShouldCloseIndex] = useState(false);

  const handleChange = (index: number) => (_: any, shouldExpand: boolean) => {
    setUserExpandedIndexes((prev) => ({ ...prev, [index]: shouldExpand }));
  };

  const getDefaultExpandedIndexes = useMemo(() => {
    if (!items?.length) return [];

    const aux: number[] = [];
    items.forEach((item, index) => {
      if (item.defaultExpanded) {
        aux.push(index);
      }
    });

    if (fixedIndex !== undefined) {
      aux.push(fixedIndex);
    }

    return [...new Set(aux)];
  }, [items, fixedIndex]);

  const expandedIndexes = useMemo(() => {
    let aux: number[] = getDefaultExpandedIndexes;

    Object.keys(userExpandedIndexes).forEach((key) => {
      const index = Number(key);
      if (userExpandedIndexes[index]) {
        aux.push(index);
      } else if (userExpandedIndexes[index] === false) {
        aux = aux.filter((i) => i !== index);
      }
    });

    if (fixedIndex !== undefined) aux.push(fixedIndex);

    return [...new Set(aux)];
  }, [userExpandedIndexes, fixedIndex, getDefaultExpandedIndexes]);

  const getExpandedValue = (index: number) => {
    if (index === fixedIndex) {
      return true;
    }

    return expandedIndexes.includes(index);
  };

  useEffect(() => {
    if (onChangeExpanded) {
      onChangeExpanded([...expandedIndexes]);
    }
  }, [expandedIndexes, onChangeExpanded]);

  const finalIndexToClose = useMemo(() => {
    if (shouldCloseIndex) {
      return indexToClose;
    }

    return -1;
  }, [shouldCloseIndex, indexToClose]);

  useEffect(() => {
    const isSameIndexes =
      getDefaultExpandedIndexes.length === defaultExpandedIndexes.length &&
      getDefaultExpandedIndexes.every(
        (value, index) => value === defaultExpandedIndexes[index]
      );

    if (!isSameIndexes || currentDefaultsKey !== defaultIndexesKey) {
      const newObject = { ...userExpandedIndexes };
      getDefaultExpandedIndexes.forEach((index) => {
        newObject[index] = undefined;
      });
      setUserExpandedIndexes(newObject);
      setDefaultExpandedIndexes(getDefaultExpandedIndexes);
      setCurrentDefaultsKey(defaultIndexesKey);
    }

    if (finalIndexToClose >= 0 && userExpandedIndexes[finalIndexToClose]) {
      setUserExpandedIndexes((prev) => ({
        ...prev,
        [finalIndexToClose]: undefined,
      }));
      setShouldCloseIndex(false);
    }
  }, [
    getDefaultExpandedIndexes,
    defaultIndexesKey,
    finalIndexToClose,
    userExpandedIndexes,
  ]);

  useEffect(() => {
    if (indexToClose >= 0) {
      setShouldCloseIndex(true);
    }
  }, [indexToClose]);

  return { handleChange, getExpandedValue };
};
