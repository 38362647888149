import Theme from "@/utils/theme";
import { Box, BoxProps } from "@mui/material";
import styled from "styled-components";

export const CardImage = styled.img`
  clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
  width: 10rem;
  height: auto;
  object-fit: cover;
`;

export const DateWrapper = (props?: BoxProps) => (
  <Box
    display="flex"
    flex={{ sm: 0.3, xs: 1 }}
    p="0.5rem"
    justifyContent="center"
    border={`1px solid ${Theme.colors.neutral.light}`}
    borderRadius="1rem"
    {...props}
  />
);
