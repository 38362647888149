import { useEffect } from "react";
import { useSignup } from "./hooks/useSignup";
import Text from "@/components/Text";
import PhoneNumberDialog from "./components/PhoneNumberDialog";
import { Box, CircularProgress, InputAdornment } from "@mui/material";
import Spacer from "@/components/Spacer";
import TextField from "@/components/TextField";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import { Gender } from "@/services/auth/types/auth.types";
import PhoneInput from "@/components/PhoneInput";
import GenderSelect from "@/components/GenderSelect";
import StateSelect from "@/components/StateSelect";
import CitySelect from "@/components/CitySelect";
import Dialog from "@/components/Dialog";
import AuthWrapper from "@/layout/AuthWrapper";
import DateInput from "@/components/DateInput";
import Divider from "@/components/Divider";
import Theme from "@/utils/theme";
import { useReality } from "@/context/RealityContext";
import { realityInfo } from "@/utils/realityInfo";
import SignupStepper from "./components/SignupStepper";
import { getTermsOfUseFile } from "@/utils/get-terms-of-use-file";
import { OtpCodeInput } from "./styles";
import ResendCodeButton from "../../components/ResendCodeButton";
import { useParams } from "react-router-dom";
import CircleCheck from "@/ui/icons/CircleCheck";
import MaskedInput from "@/components/MaskedInput";
import InfoIcon from "@/ui/icons/InfoIcon";
import { Tooltip } from "@/components/Tooltip";

export default function SignUp() {
  const { realityId } = useParams();

  const {
    isOpenPhoneNumberDialog,
    setIsOpenPhoneNumberDialog,
    isOpenAlreadyRegistered,
    isOpenChooseValidation,
    isOpenSignUp,
    isOpenBlacklistedModal,
    isOpenFinishSignUp,
    onSendSmsCode,
    onSendWhatsappCode,
    goBack,
    goToWebsite,
    goToSignIn,
    onValidatePhone,
    closeSignupDialog,
    isLoadingButton,
    currentStep,
    setCurrentStep,

    email,
    setEmail,
    emailError,
    emailConfirmation,
    setEmailConfirmation,
    emailConfirmationError,
    name,
    setName,
    nameError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    birthDate,
    setBirthDate,
    birthDateError,
    gender,
    setGender,
    selectedState,
    setSelectedState,
    selectedCity,
    setSelectedCity,
    otp,
    setOtp,
    isInvalidCode,
    smsTimerValue,
    confirmWorker,
    setConfirmWorker,
    confirmTerms,
    setConfirmTerms,

    resaleCode,
    setResaleCode,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,

    buttonText,
    onPressButton,
    resendCode,
    isValid,
  } = useSignup();
  const { isSubscriptionOpen } = useReality();

  useEffect(() => {
    setResaleCode(String(realityId));
  });

  return (
    <AuthWrapper
      pageTitle="INSCREVA-SE AQUI!"
      useSignupImages
      onClickBack={
        currentStep > 1 ? () => setCurrentStep(currentStep - 1) : undefined
      }
    >
      <SignupStepper currentStep={currentStep - 1} />

      {/* User info step */}
      <Box display={currentStep === 1 ? "block" : "none"}>
        {realityInfo.showResaleCode ? (
          <Text fontSize="1rem" mt="1rem">
            Passo 1 - Preencha seus dados para a inscrição:
          </Text>
        ) : (
          <Text fontSize="1rem" mt="1rem">
            Preencha seus dados para a inscrição:
          </Text>
        )}

        <Spacer height="1.5rem" />
        <TextField
          label="Nome completo*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!nameError}
          helperText={nameError}
        />

        <Spacer height="1rem" />
        <TextField
          label="E-mail*"
          value={email}
          setValue={setEmail}
          error={!!emailError}
          helperText={emailError}
        />

        <Spacer height="1rem" />
        <TextField
          label="Confirme seu E-mail*"
          value={emailConfirmation}
          setValue={setEmailConfirmation}
          error={!!emailConfirmationError}
          helperText={emailConfirmationError}
        />

        <Spacer height="1rem" />
        <Box display="flex" flex={1}>
          <PhoneInput
            label="Telefone (WhatsApp)*"
            value={phoneNumber}
            error={!!phoneNumberError}
            errorText={phoneNumberError}
            ContainerProps={{
              flex: 1,
              onClick: () => setIsOpenPhoneNumberDialog(true),
            }}
          />

          <Spacer width="1rem" />

          <DateInput
            label="Data Nascimento*"
            value={birthDate}
            setValue={setBirthDate}
            error={!!birthDateError}
            errorText={birthDateError}
            ContainerProps={{ flex: 1 }}
          />
        </Box>
        <Spacer height="1rem" />
        <Box display="flex" flex={1}>
          <GenderSelect
            label="Gênero*"
            value={gender}
            onChange={(e) => setGender(e.target.value as Gender)}
            ContainerProps={{ flex: 1 }}
          />
          <Spacer width="1rem" />

          <Box display="block" flex={1}>
            <StateSelect
              label="Estado*"
              value={selectedState}
              setValue={setSelectedState}
            />
          </Box>
        </Box>

        <Spacer height={"1rem"} />
        <CitySelect
          label="Cidade*"
          state={selectedState}
          value={selectedCity}
          setValue={setSelectedCity}
          ContainerProps={{ flex: 1 }}
        />

        <Divider margin="1rem 0rem" />

        <Checkbox
          value={confirmWorker}
          onChange={() => setConfirmWorker(!confirmWorker)}
        >
          <Text>{realityInfo.confirmOccupationText}</Text>
        </Checkbox>

        <Checkbox
          value={confirmTerms}
          onChange={() => setConfirmTerms(!confirmTerms)}
        >
          <Text display="inline">
            Estou de acordo com o{" "}
            <a href={getTermsOfUseFile()} target="_blank" rel="noreferrer">
              Termo de Uso
            </a>{" "}
            disponível.
          </Text>
        </Checkbox>
      </Box>

      {/* resale code step */}
      <Box
        display={
          currentStep === 2 && realityInfo.showResaleCode ? "block" : "none"
        }
      >
        <Text fontSize="1rem" mt="1rem">
          Passo 2 - Caso você possua o código da revenda, insira abaixo:
        </Text>

        <Spacer height="1.5rem" />

        <MaskedInput
          label="Código de Revenda"
          value={resaleCode}
          setValue={(val) => setResaleCode(val.toUpperCase())}
          mask="a99999"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  isOpen={isVisibleResaleCodeTooltip}
                  onClose={toggleIsVisibleResaleCodeTooltip}
                  placement={"bottom-end"}
                  title="Este campo não é obrigatório. O Código da Revenda relaciona o seu perfil com uma Loja de Materiais para Construção. Entre em contato com a revenda Quartzolit mais próxima e solicite este código gratuito."
                >
                  <Box
                    display="flex"
                    style={{ cursor: "pointer" }}
                    onClick={toggleIsVisibleResaleCodeTooltip}
                  >
                    <InfoIcon size={20} />
                  </Box>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <Text mt="1rem" color={Theme.colors.feedback.warning} fontSize="1rem">
          *Não é um código obrigatório.
        </Text>
      </Box>

      {/* otp verification step */}
      <Box
        display={
          (currentStep === 2 && !realityInfo.showResaleCode) ||
          currentStep === 3
            ? "flex"
            : "none"
        }
        flexDirection="column"
        alignItems="center"
      >
        <Text fontSize="1rem" mt="1rem">
          Passo 2 - Insira o código recebido abaixo:
        </Text>

        <Spacer height="1.5rem" />

        <OtpCodeInput
          value={otp}
          onChange={setOtp}
          isInvalid={isInvalidCode}
          onSubmit={onPressButton}
        />
        {isInvalidCode && (
          <>
            <Spacer height="0.2rem" />
            <Text color={Theme.colors.feedback.negative} fontSize="0.7rem">
              Código inválido ou inexistente.
            </Text>
          </>
        )}

        <Spacer height="2rem" />
        <Text color={Theme.colors.text.secondary}>
          Tempo de reenvio do código: {smsTimerValue}
        </Text>

        <Spacer height="2.5rem" />
        <ResendCodeButton disabled={smsTimerValue > 0} onClick={resendCode} />
      </Box>

      <Spacer height="1.5rem" />
      <Button
        size="large"
        onClick={() => onPressButton(realityInfo.showResaleCode)}
        disabled={
          !isSubscriptionOpen ||
          isLoadingButton ||
          (!isValid && currentStep === 1)
        }
      >
        {isLoadingButton ? (
          <CircularProgress style={{ color: "white" }} size="1rem" />
        ) : (
          buttonText
        )}
      </Button>

      {realityInfo.showResaleCode && currentStep === 2 && (
        <>
          <Spacer height="1rem" />
          <Button
            size="large"
            onClick={() => onPressButton()}
            disabled={!isSubscriptionOpen || isLoadingButton}
            variant="outlined"
          >
            {isLoadingButton ? (
              <CircularProgress style={{ color: "white" }} size="1rem" />
            ) : (
              "Finalizar inscrição sem o código"
            )}
          </Button>
        </>
      )}

      {/* --- Dialogs --- */}
      <PhoneNumberDialog
        isOpen={isOpenPhoneNumberDialog}
        email={phoneNumber}
        setEmail={setPhoneNumber}
        onConfirm={onValidatePhone}
        onClose={goToSignIn}
        isLoading={isLoadingButton}
        error={phoneNumberError}
      />
      <Dialog
        isOpen={isOpenAlreadyRegistered}
        text={`Este telefone já possui cadastro!\nClique abaixo para fazer o login.`}
        confirmButtonText="Entrar"
        onConfirm={goToSignIn}
        onClose={goToWebsite}
        closeButtonText="Sair"
      />

      <Dialog
        isOpen={isOpenChooseValidation}
        text={`Escolha uma opção abaixo.`}
        confirmButtonText="Receber código por SMS"
        onConfirm={() => onSendSmsCode()}
        closeButtonText="Receber código por Whatsapp"
        onClose={() => onSendWhatsappCode()}
      />
      <Dialog
        isOpen={isOpenSignUp}
        text={`Telefone verificado!\nVocê ainda não possui um cadastro. Clique abaixo em "Inscreva-se" para seguir com seu cadastro.`}
        confirmButtonText="Inscreva-se"
        onConfirm={closeSignupDialog}
        onClose={goBack}
        closeButtonText="Sair"
      />
      <Dialog
        isOpen={isOpenBlacklistedModal}
        text={realityInfo.blacklistModalText}
        confirmButtonText="Sair"
        onConfirm={goToWebsite}
      />

      <Dialog
        isOpen={isOpenFinishSignUp}
        icon={<CircleCheck />}
        text={`Telefone verificado!\nVocê possui um cadastro não finalizado. Escolha uma opção abaixo para seguir com seu cadastro.`}
        confirmButtonText="Receber código por SMS"
        onConfirm={() => onSendSmsCode()}
        closeButtonText="Receber código por Whatsapp"
        onClose={() => onSendWhatsappCode()}
      />

      <div id="recaptcha-container" />
    </AuthWrapper>
  );
}
