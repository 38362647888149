import { realityId } from "@/utils/reality-id";
import api from "../api";
import {
  EmailValidationResponse,
  SignInResponse,
  SignUpPayload,
} from "./types/auth.types";

export const firebaseSignup = async (phone: string) => {
  const res = await api.post(
    `auth/firebase-signup/${phone}?realityId=${realityId}`
  );

  return res.data;
};

export const getEmailValidation = async (
  email: string
): Promise<EmailValidationResponse | undefined> => {
  const res = await api.post<EmailValidationResponse>(`auth/email-validation`, {
    email,
    realityId,
  });

  return res.data;
};

export const signUp = async (
  payload: SignUpPayload
): Promise<SignInResponse> => {
  const res = await api.post<SignInResponse>(`auth/sign-up`, {
    ...payload,
    realityId,
  });

  return res.data;
};

export const signIn = async (
  email: string,
  password: string
): Promise<SignInResponse> => {
  const res = await api.post<SignInResponse>(`auth/sign-in`, {
    email,
    password,
    realityId,
  });

  return res.data;
};

export const sendPasswordRecoveryCode = async (
  email: string
): Promise<void> => {
  return api.post(`auth/password-recovery/request`, {
    email,
  });
};

export const changeUserPassword = async (
  email: string,
  verificationCode: string,
  newPassword: string
): Promise<void> => {
  return api.post(`auth/password-recovery/reset-verification`, {
    email,
    verificationCode,
    newPassword,
  });
};

export const sendWhatsappCode = async (phone: string): Promise<void> => {
  return api.post(`auth/send-otp-whatsapp/${phone}?realityId=${realityId}`);
};

export const validateWhatsappCode = async (phone: string, code: string) => {
  const res = await api.get<{
    isValid: boolean;
    isExpired: boolean;
    accessToken: string;
  }>(`auth/validate-otp/${phone}/${code}?realityId=${realityId}`);

  return res.data;
};
