import { memo } from "react";
import {
  Container,
  Content,
  PageContent,
  WebsiteButtonWrapper,
} from "./styles";
import Text from "@/components/Text";
import { realityInfo } from "@/utils/realityInfo";
import Button from "@/components/Button";
import Theme from "@/utils/theme";
import { useFooterWrapper } from "./useFooterWrapper";
import { FooterProps } from "./props.type";

const FooterWrapper = ({
  variant = "default",
  showWebsiteButton,
  children,
}: FooterProps) => {
  const { onClickGoToWebsite, containerColor, textColor } =
    useFooterWrapper(variant);

  return (
    <Container bgColor={containerColor}>
      <PageContent>{children}</PageContent>

      {showWebsiteButton && !!realityInfo.showWebsiteButton && (
        <WebsiteButtonWrapper>
          <Button
            variant="outlined"
            fullWidth={false}
            style={{ padding: "0.5rem 2rem" }}
            onClick={onClickGoToWebsite}
          >
            <Text color={Theme.colors.button.outlined.primary}>
              Ir para o site
            </Text>
          </Button>
        </WebsiteButtonWrapper>
      )}

      <Content variant={variant}>
        <Text color={textColor || "#93999F"}>{realityInfo.footerText}</Text>
      </Content>
    </Container>
  );
};

export default memo(FooterWrapper);
