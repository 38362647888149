import CardWithShadow from "@/components/CardWithShadow";
import ChevronButton from "@/components/ChevronButton";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { Box } from "@mui/material";
import { useRegulation } from "./useRegulation";
import { TableRow, TableRowHeader, TableRowItem, TableWrapper } from "./styles";
import Divider from "@/components/Divider";
import { useMemo } from "react";

export default function Regulation() {
  const {
    onOpenRegulation,
    stages,
    lastChallengeId,
    formattedCourses,
    totalPointsCourse,
  } = useRegulation();

  const MergedChallengeAndCourseByStage = useMemo(() => {
    if (!stages || !formattedCourses) return [];

    return stages.map((stage) => {
      const course = formattedCourses;
      return {
        ...stage,
        totalPoints:
          stage.id === 1
            ? stage.totalPoints + totalPointsCourse
            : stage.totalPoints,
        items: stage.id === 1 ? [...stage.items, ...course] : [...stage.items],
      };
    });
  }, [stages, formattedCourses, totalPointsCourse]);

  return (
    <Box display="flex" flexDirection="column">
      <CardWithShadow
        flexDirection="row"
        alignItems="center"
        maxWidth="max-content"
        alignSelf={{ xs: "center", lg: "inherit" }}
      >
        <Box
          p="1rem"
          flexDirection="row"
          alignItems="center"
          borderRight="1px solid"
          borderColor={Theme.colors.neutral.light}
        >
          <Text fontSize="1rem" ml="0.5rem">
            REGULAMENTO
          </Text>
        </Box>

        <Box flexDirection="row" p="1rem" gap="1rem">
          <ChevronButton
            label="Abrir Regulamento"
            onClick={onOpenRegulation}
          ></ChevronButton>
        </Box>
      </CardWithShadow>

      <CardWithShadow mt="1rem" p="1rem 1.5rem">
        <Text>Pontuações</Text>

        <TableWrapper>
          {MergedChallengeAndCourseByStage?.map((stage) => (
            <>
              <TableRowHeader>
                <TableRowItem>
                  <Text
                    color={Theme.colors.regulation?.brand_dark}
                    fontWeight={500}
                  >
                    {stage.title.toUpperCase()}: {stage.subTitle}
                  </Text>
                </TableRowItem>

                <Divider
                  vertical
                  margin="1px 0px"
                  color={Theme.colors.brand.primary}
                />

                <TableRowItem>
                  <Text
                    color={Theme.colors.regulation?.brand_dark}
                    fontWeight={500}
                  >
                    PONTUAÇÃO MÁXIMA: {stage.totalPoints}
                  </Text>
                </TableRowItem>
              </TableRowHeader>

              <Divider />

              {stage.items.map((item) => (
                <>
                  <TableRow>
                    <TableRowItem>
                      <Text fontWeight={500}>{item.title}</Text>
                    </TableRowItem>
                    <Divider vertical />
                    <TableRowItem>
                      <Text fontWeight={500}>{item.points} PONTOS</Text>
                    </TableRowItem>
                  </TableRow>

                  {lastChallengeId !== item.id && <Divider />}
                </>
              ))}
            </>
          ))}
        </TableWrapper>
      </CardWithShadow>
    </Box>
  );
}
