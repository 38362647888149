import { useEffect, useState } from "react";

export const useCountdown = (totalSeconds = 300) => {
  const [seconds, setSeconds] = useState(totalSeconds);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);

      return () => clearTimeout(timer);
    }
  }, [seconds]);

  const restart = () => {
    setSeconds(totalSeconds);
  };

  return { value: seconds, restart };
};
