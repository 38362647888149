import { useCitySelect } from "./useCitySelect";
import { CitySelectProps } from "./props.type";
import CustomAutocomplete from "../CustomAutocomplete";

const CitySelect = (props: CitySelectProps) => {
  const { options, isLoading } = useCitySelect(props);

  return (
    <CustomAutocomplete
      label="Cidade"
      disabled={props.disabled || options.length === 0 || isLoading}
      options={options}
      value={props.value ?? ""}
      setValue={props.setValue}
    />
  );
};

export default CitySelect;
