import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { useQuizChallengeSubmission } from "./useQuizChallengeSubmission";
import ShapedAccordion from "@/components/ShapedAccordion";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import ChallengeSubmissionWrapper from "@/layout/ChallengeSubmissionWrapper";
import { OutlinedBox } from "./styles";
import QuizIcon from "/assets/icons/quiz.svg";
import QuizQuestionsDialog from "../QuizQuestionsDialog";
import { useQuizQuestionsDialog } from "../QuizQuestionsDialog/useQuizQuestionsDialog";
import Chip from "@/components/Chip";
import Divider from "@/components/Divider";

// TODO: verificar como fica dps que etapa termina
function QuizChallengeSubmission() {
  const {
    challenge,
    setChallenge,
    challengeStatus,
    quizStatus,

    toggleQuiz,
    isOpenQuiz,
  } = useQuizChallengeSubmission();

  const { isLoading } = useQuizQuestionsDialog({
    challenge,
    onClose: toggleQuiz,
  });

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <ChallengeSubmissionWrapper type="quiz" onLoadChallenge={setChallenge}>
      <ShapedAccordion
        summaryStyle={{ backgroundColor: Theme.colors.brand.secondary }}
        fixedIndex={0}
        items={[
          {
            summary: (
              <Box display="flex" alignItems="center">
                <Text color={Theme.colors.neutral.light}>Cases</Text>
                <Text color={Theme.colors.neutral.mid}> | </Text>
                <Chip
                  label={challengeStatus.text}
                  color={challengeStatus.color}
                />
              </Box>
            ),

            details: isLoading ? (
              <CircularProgress
                style={{ color: Theme.colors.brand.secondary }}
                size="1.5rem"
              />
            ) : (
              <Box display="flex" flexDirection="column" p="1.5rem" flex={1}>
                <OutlinedBox>
                  <img src={QuizIcon} />
                  <Text ml={1} color={Theme.colors.neutral.dark}>
                    Case 01 <Divider vertical />
                  </Text>

                  <Text color={quizStatus.color} ml="0.2rem" mr="0.2rem">
                    {quizStatus.text}
                  </Text>

                  <Spacer grow />

                  <Button
                    variant="outlined"
                    fullWidth={isMobile}
                    onClick={toggleQuiz}
                    style={{ padding: "0.2rem 1rem" }}
                  >
                    {!!challenge?.submission ? (
                      <Text>Detalhes </Text>
                    ) : (
                      "Iniciar Case"
                    )}
                    <Spacer width="0.5rem" />
                    {">"}
                  </Button>
                </OutlinedBox>
              </Box>
            ),
            shadowColor: "#FECC09",
          },
        ]}
        challenge={undefined}
      />

      <QuizQuestionsDialog
        isOpen={isOpenQuiz}
        challenge={challenge!}
        onClose={toggleQuiz}
      />
    </ChallengeSubmissionWrapper>
  );
}

export default QuizChallengeSubmission;
