import { useLoader } from "@/context/LoaderContext";
import { useUser } from "@/context/UserContext";
import { getCourses } from "@/services/courses";
import { useEffect } from "react";
import { useQuery } from "react-query";

export const useCourses = (enabled = true) => {
  const loader = useLoader();
  const { userInfo } = useUser();

  const fetchCourses = () => getCourses();
  const { data: courses, isLoading } = useQuery("get-courses", fetchCourses, {
    enabled,
  });

  const onGoToCourse = (courseUrl: string) => {
    const coursePath = courseUrl + "/" + userInfo?.wordpressId;

    window.location.href = coursePath;
  };

  useEffect(() => {
    if (isLoading) {
      loader.show("get-user-courses");
    } else {
      loader.hide("get-user-courses");
    }
  }, [isLoading]);

  return { courses, onGoToCourse };
};
