import { isValidDate } from "@/helpers/date.helper";
import { Gender } from "@/services/auth/types/auth.types";
import { realityInfo } from "@/utils/realityInfo";
import { emailRegex, fullNameRegex, fullPhoneNumberRegex } from "@/utils/regex";
import { isAfter } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithCustomToken,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "@/firebase.config";
import {
  firebaseSignup,
  sendWhatsappCode,
  signUp,
  validateWhatsappCode,
} from "@/services/auth";
import { useLoader } from "@/context/LoaderContext";
import {
  getEmailAvailability,
  getPhoneNumberAvailability,
  updateUserInfo,
  setReseleCodeAvailability,
} from "@/services/users";
import { useCountdown } from "@/hooks/useCountdown";
import { useNavigate } from "react-router-dom";
import { useResaleCode } from "@/hooks/useResaleCode";
import { getBlacklist } from "@/services/blacklist";

interface CustomWindow extends Window {
  confirmationResult?: ConfirmationResult | null;
  recaptchaVerifier?: RecaptchaVerifier;
  recaptchaWidgetId?: number;
}

declare const window: CustomWindow;

// Comes from Google reCAPTCHA V3 script included in the HTML file
declare const grecaptcha: any;

export const useSignup = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const { validateResaleCode, addUserResaleCode } = useResaleCode();
  const { value: smsTimerValue, restart: restartTimer } = useCountdown();

  const [isOpenPhoneNumberDialog, setIsOpenPhoneNumberDialog] = useState(true);
  const [isOpenAlreadyRegistered, setIsOpenAlreadyRegistered] = useState(false);
  const [isOpenChooseValidation, setIsOpenChooseValidation] = useState(false);
  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [isOpenBlacklistedModal, setIsOpenBlacklistedModal] = useState(false);
  const [isOpenFinishSignUp, setIsOpenFinishSignUp] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const [email, setEmail] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState<Gender>();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [confirmWorker, setConfirmWorker] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [resaleCode, setResaleCode] = useState("");
  const [otp, setOtp] = useState("");
  const [isVisibleResaleCodeTooltip, setIsVisibleResaleCodeTooltip] =
    useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const nextStep = () => setCurrentStep((prev) => prev + 1);

  const [validationType, setValidationType] = useState<"sms" | "whatsapp">();

  const rawPhoneNumber = useMemo(() => {
    if (!phoneNumber) return "";

    return "+55" + phoneNumber.replace(/[^\d]/g, "");
  }, [phoneNumber]);

  const phoneNumberError = useMemo(() => {
    if (rawPhoneNumber?.length === 0) return "";

    const isValid = fullPhoneNumberRegex.test(rawPhoneNumber);

    if (!isValid) {
      return "Número de telefone inválido.";
    }

    return "";
  }, [rawPhoneNumber]);

  const emailError = useMemo(() => {
    const match = email.match(emailRegex);

    if (!match && email.length > 0) {
      return "Formato de email inválido";
    }

    return "";
  }, [email]);

  const emailConfirmationError = useMemo(() => {
    if (email?.length === 0 || emailConfirmation?.length === 0) return "";

    if (email !== emailConfirmation) {
      return "Os e-mails devem ser iguais.";
    }

    return "";
  }, [email, emailConfirmation]);

  const nameError = useMemo(() => {
    if (!name) return "";

    const isValid = fullNameRegex.test(name);

    if (!isValid) {
      return "Nome incompleto.";
    }

    return "";
  }, [name]);

  const rawBirthdate = useMemo(() => {
    if (!birthDate) return "";

    const [day, month, year] = birthDate.split("/");

    return `${year}-${month}-${day}`;
  }, [birthDate]);

  const birthDateError = useMemo(() => {
    if (rawBirthdate?.length === 0) return "";

    const year = rawBirthdate.split("-").shift();
    if (
      !isValidDate(rawBirthdate) ||
      isAfter(rawBirthdate, new Date()) ||
      Number(year) < 1901
    ) {
      return "Data inválida.";
    }

    return "";
  }, [rawBirthdate]);

  const isValid = useMemo(() => {
    return (
      !!email &&
      !!name &&
      !nameError &&
      !!rawPhoneNumber &&
      !phoneNumberError &&
      !!rawBirthdate &&
      !birthDateError &&
      !emailError &&
      !emailConfirmationError &&
      !!gender &&
      !!selectedState &&
      !!selectedCity &&
      confirmWorker &&
      confirmTerms
    );
  }, [
    email,
    name,
    nameError,
    phoneNumberError,
    birthDateError,
    gender,
    selectedState,
    selectedCity,
    confirmWorker,
    confirmTerms,
    emailError,
    emailConfirmationError,
  ]);

  const buttonText = useMemo(() => {
    if (currentStep === 2) {
      if (realityInfo.showResaleCode) {
        return "Finalizar inscrição com o código";
      }

      return "Validar código";
    }

    return "Finalizar inscrição";
  }, [realityInfo.showResaleCode, currentStep]);

  const goBack = () => {
    window.history.back();
  };

  const goToSignIn = () => {
    navigate(`/sign-in`);
  };

  const goToWebsite = () => {
    window.location.href = realityInfo.websiteUrl!;
  };

  const onValidatePhone = async () => {
    const isValidPhone =
      phoneNumberError.length === 0 && rawPhoneNumber.length > 0;
    if (!isValidPhone) return;

    try {
      setIsLoadingButton(true);
      const blacklist = await getBlacklist();

      if (blacklist.phones.includes(rawPhoneNumber)) {
        return setIsOpenBlacklistedModal(true);
      }

      const { isAvailable, isPhoneNumberValidated, userId } =
        await getPhoneNumberAvailability(rawPhoneNumber);
      setIsOpenPhoneNumberDialog(false);

      if (!isAvailable) {
        await setReseleCodeAvailability(userId, resaleCode);
      }
      if (isAvailable) {
        return setIsOpenSignUp(true);
      } else if (!isPhoneNumberValidated) {
        return setIsOpenFinishSignUp(true);
      }

      setIsOpenAlreadyRegistered(true);
    } catch (err) {
      console.error("Error validating phone", err);
      toast("Erro validando telefone", { type: "error" });
    } finally {
      setIsLoadingButton(false);
    }
  };

  const closeSignupDialog = () => {
    setIsOpenSignUp(false);
  };

  const toggleIsVisibleResaleCodeTooltip = () => {
    setIsVisibleResaleCodeTooltip((old) => !old);
  };

  const verifyEmailAvailability = async () => {
    try {
      loader.show("verify-email");
      const { isAvailable } = await getEmailAvailability(email);

      if (!isAvailable) {
        toast("E-mail já cadastrado", { type: "error" });
        return false;
      }

      return true;
    } catch (err) {
      toast("Erro verificando e-mail", { type: "error" });
      console.error("Erro verificando e-mail");
    } finally {
      loader.hide("verify-email");
    }
  };

  const createAccount = async (finalResaleCode?: string) => {
    try {
      loader.show("sign_up");
      await signUp({
        email,
        name,
        phoneNumber: rawPhoneNumber,
        birthDate: rawBirthdate,
        gender: gender!,
        state: selectedState,
        city: selectedCity,
        resaleCode: finalResaleCode,
      });

      if (finalResaleCode) {
        await addUserResaleCode(finalResaleCode, email);
      }
      return true;
    } catch (err) {
      toast("Erro ao criar conta", { type: "error" });
      console.error("Erro ao criar conta", err);
      return false;
    } finally {
      loader.hide("sign_up");
    }
  };

  const validateOtpCode = async () => {
    if (validationType === "sms") {
      setIsInvalidCode(false);

      try {
        loader.show("verify_code");

        await window.confirmationResult?.confirm(otp);
      } catch (error) {
        toast("Código inválido ou inexistente", { type: "error" });
        console.error("Código inválido ou inexistente");
        resetReCaptcha();

        return false;
      } finally {
        loader.hide("verify_code");
      }
    } else {
      try {
        loader.show("verify_code");
        const { isExpired, isValid, accessToken } = await validateWhatsappCode(
          rawPhoneNumber,
          otp
        );

        if (isExpired) {
          toast("Código expirado!", { type: "error" });
          return false;
        }

        if (!isValid) {
          toast("Código inválido ou inexistente", { type: "error" });
          return false;
        }

        await signInWithCustomToken(auth, accessToken);
        return true;
      } catch (err) {
        toast("Erro ao validar o código", { type: "error" });
        console.error("Erro ao validar o código", err);
      } finally {
        loader.hide("verify_code");
      }
    }

    return true;
  };

  const onPressButton = async (withResaleCode = false) => {
    if (currentStep === 1) {
      if (!isValid) {
        return toast("Preencha todos os campos corretamente", {
          type: "error",
        });
      }

      const isAvailableEmail = await verifyEmailAvailability();
      if (!isAvailableEmail) return;

      const blacklist = await getBlacklist();

      if (blacklist.emails.includes(email.toLocaleLowerCase())) {
        return setIsOpenBlacklistedModal(true);
      }

      if (withResaleCode) {
        return nextStep();
      }

      const accountCreated = await createAccount();
      if (!accountCreated) return;

      return setIsOpenChooseValidation(true);
    }

    if (currentStep === 2 && withResaleCode) {
      if (!resaleCode) {
        return toast(
          "Para seguir com o código, é necessário preencher o campo do código da revenda.",
          { type: "error" }
        );
      }

      const isValid = await validateResaleCode(resaleCode);
      if (!isValid) {
        return toast(
          "O código da revenda inserido está incorreto. Verifique e tente novamente.",
          { type: "error" }
        );
      }

      const accountCreated = await createAccount(resaleCode);
      if (!accountCreated) return;
      return setIsOpenChooseValidation(true);
    }

    if (otp.length < 6) {
      return setIsInvalidCode(true);
    }

    const valid = await validateOtpCode();
    console.log("🚀 ~ onPressButton ~ valid:", valid);
    if (!valid) return setIsInvalidCode(true);

    await updateUserInfo({ phoneNumberValidated: true });
    // navigate("/dashboard");
  };

  const createFirebaseUser = async () => {
    if (!!phoneNumberError || !rawPhoneNumber) {
      return;
    }

    try {
      setIsOpenFinishSignUp(false);
      loader.show("firebase_signup");
      await firebaseSignup(rawPhoneNumber);
    } catch (error) {
      console.error("Erro ao criar conta no firebase", error);
    } finally {
      loader.hide("firebase_signup");
    }
  };

  const onSendSmsCode = async () => {
    setValidationType("sms");
    await createFirebaseUser();

    if (window.recaptchaWidgetId !== undefined) {
      const widgetId = await window.recaptchaVerifier!.render();
      window.recaptchaWidgetId = widgetId;
    }

    sendSignInSmsCode();
  };

  const onSendWhatsappCode = async (disableNextStep = false) => {
    setValidationType("whatsapp");
    await createFirebaseUser();

    try {
      loader.show("send_whatsapp_code");
      await sendWhatsappCode(rawPhoneNumber);
      if (!disableNextStep) nextStep();
    } catch (err) {
      console.error("Erro ao enviar código", err);
      toast("Erro ao enviar código", { type: "error" });
    } finally {
      setIsOpenChooseValidation(false);
      loader.hide("send_whatsapp_code");
    }
  };

  const sendSignInSmsCode = async (disableNextStep = false) => {
    loader.show("sign_in");
    const appVerifier = window.recaptchaVerifier;

    if (!rawPhoneNumber.length) return;

    signInWithPhoneNumber(auth, rawPhoneNumber, appVerifier!)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        if (!disableNextStep) nextStep();
      })
      .catch((error) => {
        console.error("Erro ao enviar código", error);
        toast("Erro ao enviar código", { type: "error" });
        resetReCaptcha();
      })
      .finally(() => {
        loader.hide("sign_in");
        setIsOpenChooseValidation(false);
      });
  };

  const resendCode = () => {
    restartTimer();
    if (validationType === "sms") {
      sendSignInSmsCode(true);
    } else {
      onSendWhatsappCode(true);
    }
  };

  const resetReCaptcha = () => {
    if (
      typeof grecaptcha !== "undefined" &&
      typeof window.recaptchaWidgetId !== "undefined"
    ) {
      grecaptcha.reset(window.recaptchaWidgetId);
    }
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (_response: any) {
          sendSignInSmsCode();
        },
      }
    );
  }, []);

  useEffect(() => {
    resetReCaptcha();
  }, [rawPhoneNumber]);

  useEffect(() => {
    restartTimer();
  }, [currentStep]);

  return {
    isOpenPhoneNumberDialog,
    setIsOpenPhoneNumberDialog,
    isOpenAlreadyRegistered,
    isOpenChooseValidation,
    setIsOpenChooseValidation,
    isOpenSignUp,
    isOpenBlacklistedModal,
    isOpenFinishSignUp,
    onSendSmsCode,
    onSendWhatsappCode,
    goBack,
    goToWebsite,
    goToSignIn,
    onValidatePhone,
    closeSignupDialog,
    isLoadingButton,
    currentStep,
    setCurrentStep,

    email,
    setEmail,
    emailError,
    emailConfirmation,
    setEmailConfirmation,
    emailConfirmationError,
    name,
    setName,
    nameError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    birthDate,
    setBirthDate,
    birthDateError,
    gender,
    setGender,
    selectedState,
    setSelectedState,
    selectedCity,
    setSelectedCity,
    otp,
    setOtp,
    isInvalidCode,
    smsTimerValue,

    confirmWorker,
    setConfirmWorker,
    confirmTerms,
    setConfirmTerms,

    resaleCode,
    setResaleCode,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,

    buttonText,
    onPressButton,
    sendSignInSmsCode,
    isValid,
    resendCode,
  };
};
