import Text from "@/components/Text";
import Theme from "@/utils/theme";
import ShapedAccordion from "@/components/ShapedAccordion";
import { Box, useMediaQuery } from "@mui/material";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import ChallengeSubmissionWrapper from "@/layout/ChallengeSubmissionWrapper";
import { OutlinedBox } from "./styles";
import QuizIcon from "/assets/icons/quiz.svg";
import QuizQuestionsDialog from "../QuizQuestionsDialog";
import Chip from "@/components/Chip";
import Divider from "@/components/Divider";
import { useCaseChallengeSubmission } from "./useQuizChallengeSubmission";

function CaseChallengeSubmission() {
  const {
    challenge,
    setChallenge,
    challengeStatus,
    quizzes,

    currentQuiz,
    setIsOpenQuizId,
  } = useCaseChallengeSubmission();

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <ChallengeSubmissionWrapper type="case" onLoadChallenge={setChallenge}>
      <ShapedAccordion
        summaryStyle={{ backgroundColor: Theme.colors.brand.secondary }}
        fixedIndex={0}
        items={[
          {
            summary: (
              <Box display="flex" alignItems="center">
                <Text color={Theme.colors.neutral.light}>Cases</Text>
                <Text color={Theme.colors.neutral.mid}> | </Text>
                <Chip
                  label={challengeStatus.text}
                  color={challengeStatus.color}
                />
              </Box>
            ),

            details: (
              <Box display="flex" flexDirection="column" p="1.5rem" flex={1}>
                {quizzes?.map((quiz, index) => (
                  <>
                    <OutlinedBox>
                      <img src={QuizIcon} />
                      <Text ml={1} color={Theme.colors.neutral.dark}>
                        Case 0{quiz.order}
                        <Divider vertical />
                      </Text>

                      <Text color={quiz.status.color} ml="0.2rem" mr="0.2rem">
                        {quiz.status.text}
                      </Text>

                      <Spacer grow />

                      <Button
                        variant="outlined"
                        fullWidth={isMobile}
                        onClick={() => {
                          setIsOpenQuizId(quiz.id);
                        }}
                        style={{ padding: "0.2rem 1rem" }}
                      >
                        {!!quiz?.submission ? "Detalhes" : "Iniciar Case"}
                        <Spacer width="0.5rem" />
                        {">"}
                      </Button>
                    </OutlinedBox>

                    {index !== (challenge?.quizzes?.length ?? 0) - 1 && (
                      <Spacer height="1rem" />
                    )}

                    <QuizQuestionsDialog
                      isOpen={currentQuiz?.id === quiz.id}
                      challenge={currentQuiz!}
                      onClose={() => setIsOpenQuizId(undefined)}
                    />
                  </>
                ))}
              </Box>
            ),
            shadowColor: "#FECC09",
          },
        ]}
      />
    </ChallengeSubmissionWrapper>
  );
}

export default CaseChallengeSubmission;
