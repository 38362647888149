import { useStages } from "@/context/StagesContext";
import { useUser } from "@/context/UserContext";
import {
  getFormattedDate,
  getDateWithoutTimezone,
  isValidDate,
} from "@/helpers/date.helper";
import { updateUserInfo } from "@/services/users";
import { getRegion } from "@/utils/get-localities";
import {
  fullNameRegex,
  fullPhoneNumberRegex,
  phoneNumberRegex,
} from "@/utils/regex";
import { isAfter } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

export const useProfile = () => {
  const { userInfo, refetchUserInfo } = useUser();
  const { currentStageNumber } = useStages();

  const [imageUrl, setImageUrl] = useState(userInfo?.imageUrl);
  const [name, setName] = useState(userInfo?.name);
  const [email, setEmail] = useState(userInfo?.email);
  const [phoneNumber, setPhoneNumber] = useState(
    userInfo?.phoneNumber.slice(3)
  );
  const [birthDate, setBirthDate] = useState(
    getFormattedDate(userInfo?.birthDate)
  );
  const [state, setState] = useState(userInfo?.state);
  const [region, setRegion] = useState("");
  const [city, setCity] = useState(userInfo?.city);
  const [instagramAccount, setInstagramAccount] = useState(
    userInfo?.instagramAccount
  );
  const [gender, setGender] = useState(userInfo?.gender);
  const [address, setAddress] = useState(userInfo?.address);
  const [numberAddress, setNumberAddress] = useState(userInfo?.numberAddress);
  const [complement, setComplement] = useState(userInfo?.complement);
  const [cep, setCep] = useState(userInfo?.cep);
  const [shirtSize, setShirtSize] = useState(userInfo?.shirtSize);

  const [cpf, setCpf] = useState(userInfo?.cpf);
  const [phoneNumberResponsible, setPhoneNumberResponsible] = useState(
    userInfo?.phoneNumberResponsible
  );
  const [shoeSize, setShoeSize] = useState(userInfo?.shoeSize);
  const [pantsSize, setPantsSize] = useState(userInfo?.pantsSize);

  const [isLoading, setIsLoading] = useState(false);

  const [showChangesDialog, setShowChangesDialog] = useState(false);
  const toggleChangesDialog = () => setShowChangesDialog(!showChangesDialog);

  const [showConfirmChangesDialog, setShowConfirmChangesDialog] =
    useState(false);
  const toggleConfirmDialog = () =>
    setShowConfirmChangesDialog(!showConfirmChangesDialog);

  const [showChangesSavedDialog, setShowChangesSavedDialog] = useState(false);
  const toggleChangesSavedDialog = () =>
    setShowChangesSavedDialog(!showChangesSavedDialog);

  const [isOpenInstagramTooltip, setIsOpenInstagramTooltip] = useState(false);
  const toggleTooltip = () =>
    setIsOpenInstagramTooltip(!isOpenInstagramTooltip);

  const rawPhoneNumber = useMemo(() => {
    if (!phoneNumber) return "";

    return "+55" + phoneNumber.replace(/[^\d]/g, "");
  }, [phoneNumber]);

  const hasChanges = useMemo(() => {
    let isDifferent =
      imageUrl !== userInfo?.imageUrl ||
      name !== userInfo?.name ||
      email !== userInfo?.email ||
      rawPhoneNumber !== userInfo?.phoneNumber ||
      birthDate !== getFormattedDate(userInfo?.birthDate) ||
      state !== userInfo?.state ||
      city !== userInfo?.city ||
      instagramAccount !== userInfo?.instagramAccount ||
      gender !== userInfo?.gender;

    if (currentStageNumber > 1) {
      isDifferent =
        isDifferent ||
        address !== userInfo?.address ||
        numberAddress !== userInfo?.numberAddress ||
        complement !== userInfo?.complement ||
        cep !== userInfo?.cep ||
        shirtSize !== userInfo?.shirtSize;
    }
    if (currentStageNumber > 2) {
      isDifferent =
        isDifferent ||
        cpf !== userInfo?.cpf ||
        phoneNumberResponsible !== userInfo?.phoneNumberResponsible ||
        shoeSize !== userInfo?.shoeSize ||
        pantsSize !== userInfo?.pantsSize;
    }

    return isDifferent;
  }, [
    imageUrl,
    name,
    email,
    phoneNumber,
    birthDate,
    state,
    city,
    instagramAccount,
    gender,
    address,
    numberAddress,
    complement,
    cep,
    cpf,
    phoneNumberResponsible,
    shoeSize,
    pantsSize,
    shirtSize,
    currentStageNumber,
    userInfo,
    rawPhoneNumber,
  ]);

  const rawPhoneNumberContactResponse = useMemo(
    () => phoneNumberResponsible?.replace(/[^\d]/g, "") ?? "",
    [phoneNumberResponsible]
  );

  const phoneNumberError = useMemo(() => {
    if (rawPhoneNumber?.length === 0) return "";

    const isValid = fullPhoneNumberRegex.test(rawPhoneNumber);

    if (!isValid) {
      return "Número inválido.";
    }

    return "";
  }, [rawPhoneNumber]);

  const nameError = useMemo(() => {
    if (!name) return "";

    const isValid = fullNameRegex.test(name);

    if (!isValid) {
      return "Nome incompleto.";
    }

    return "";
  }, [name]);

  const phoneNumberResponsibleError = useMemo(() => {
    if (rawPhoneNumberContactResponse?.length === 0) return "";

    const isValid = phoneNumberRegex.test(rawPhoneNumberContactResponse);

    if (!isValid) {
      return "Número inválido.";
    }

    return "";
  }, [rawPhoneNumberContactResponse]);

  const rawBirthdate = useMemo(() => {
    if (!birthDate) return "";

    const [day, month, year] = birthDate.split("/");

    return `${year}-${month}-${day}`;
  }, [birthDate]);

  const birthDateError = useMemo(() => {
    if (rawBirthdate?.length === 0) return "";

    const year = rawBirthdate.split("-").shift();
    if (
      !isValidDate(rawBirthdate) ||
      isAfter(rawBirthdate, new Date()) ||
      Number(year) < 1901
    ) {
      return "Data inválida.";
    }

    return "";
  }, [rawBirthdate]);

  const isValid = useMemo(() => {
    let hasAllFields =
      !!name &&
      !nameError &&
      !!email &&
      !!phoneNumber &&
      !!birthDate &&
      !!state &&
      !!city &&
      !!gender &&
      !birthDateError &&
      !phoneNumberError;

    if (currentStageNumber > 1) {
      hasAllFields =
        hasAllFields &&
        !!address &&
        !!numberAddress &&
        !!complement &&
        !!cep &&
        !!shirtSize &&
        !!instagramAccount;
    }

    return hasAllFields;
  }, [
    name,
    nameError,
    email,
    phoneNumber,
    birthDate,
    state,
    city,
    instagramAccount,
    gender,
    birthDateError,
    phoneNumberError,
    address,
    numberAddress,
    complement,
    cep,
    shirtSize,
    currentStageNumber,
  ]);

  const onPressCancel = () => {
    if (hasChanges) {
      return toggleChangesDialog();
    }
  };

  const onPressSave = () => {
    setShowConfirmChangesDialog(true);
  };

  const onCancelChanges = () => {
    resetInfo();

    toggleChangesDialog();
  };

  const onSaveChanges = async () => {
    try {
      setIsLoading(true);

      if (!isValid) {
        toggleConfirmDialog();
        return toast("Preencha todos os campos corretamente.", {
          type: "error",
        });
      }

      await updateUserInfo({
        imageUrl,
        name,
        email,
        birthDate: getDateWithoutTimezone(rawBirthdate).toISOString(),
        state,
        city,
        instagramAccount,
        gender,
        address,
        numberAddress,
        complement,
        cep,
        shirtSize,
        cpf: cpf?.replace(/[.-]/g, ""),
        phoneNumberResponsible: phoneNumberResponsible?.replace(/[() -]/g, ""),
        shoeSize,
        pantsSize,
      });
      refetchUserInfo();

      toggleConfirmDialog();
      toggleChangesSavedDialog();
    } catch (err) {
      console.error("Error updating profile info", err);
      toast("Erro salvando informações", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const resetInfo = () => {
    setImageUrl(userInfo?.imageUrl);
    setName(userInfo?.name);
    setEmail(userInfo?.email);
    setPhoneNumber(userInfo?.phoneNumber.slice(3));
    setBirthDate(getFormattedDate(userInfo?.birthDate));
    setState(userInfo?.state);
    setCity(userInfo?.city);
    setInstagramAccount(userInfo?.instagramAccount);
    setGender(userInfo?.gender);
    setAddress(userInfo?.address);
    setNumberAddress(userInfo?.numberAddress);
    setComplement(userInfo?.complement);
    setCep(userInfo?.cep);
    setShirtSize(userInfo?.shirtSize);
    setCpf(userInfo?.cpf);
    setPhoneNumberResponsible(userInfo?.phoneNumberResponsible);
    setShoeSize(userInfo?.shoeSize);
    setPantsSize(userInfo?.pantsSize);
  };

  const updateRegion = async () => {
    if (!state) {
      setRegion("");
      return;
    }

    const res = await getRegion(state);
    setRegion(res);
  };

  useEffect(() => {
    updateRegion();
  }, [state]);

  useEffect(() => {
    updateRegion();
  }, []);

  useEffect(() => {
    resetInfo();
  }, [userInfo]);

  return {
    imageUrl,
    setImageUrl,
    name,
    setName,
    nameError,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    birthDate,
    setBirthDate,
    birthDateError,
    state,
    setState,
    city,
    setCity,
    region,
    setRegion,
    instagramAccount,
    setInstagramAccount,
    gender,
    setGender,
    address,
    setAddress,
    numberAddress,
    setNumberAddress,
    complement,
    setComplement,
    cep,
    setCep,
    shirtSize,
    setShirtSize,
    cpf,
    setCpf,
    phoneNumberResponsible,
    setPhoneNumberResponsible,
    phoneNumberResponsibleError,
    shoeSize,
    setShoeSize,
    pantsSize,
    setPantsSize,
    isLoading,
    hasChanges,
    onPressCancel,
    onPressSave,
    showChangesDialog,
    toggleChangesDialog,
    showConfirmChangesDialog,
    toggleConfirmDialog,
    showChangesSavedDialog,
    toggleChangesSavedDialog,
    onCancelChanges,
    onSaveChanges,

    isOpenInstagramTooltip,
    toggleTooltip,
  };
};
