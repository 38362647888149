import { useParams } from "react-router-dom";
import MediaChallengeSubmission from "./components/MediaChallengeSubmission";
import QuizChallengeSubmission from "./components/QuizChallengeSubmission";
import CaseChallengeSubmission from "./components/CaseChallengeSubmission";

function ChallengeSubmission() {
  const { type } = useParams();

  if (type === "case") {
    return <CaseChallengeSubmission />;
  }

  if (type === "quiz") {
    return <QuizChallengeSubmission />;
  }

  return <MediaChallengeSubmission />;
}

export default ChallengeSubmission;
