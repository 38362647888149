import { useMemo } from "react";
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "react-query";
import { useUser } from "@/context/UserContext";

export function useAuthenticatedQuery<T>(
  queryKey: (string | undefined)[],
  queryFn: QueryFunction<T, (string | undefined)[]>,
  options?: Omit<
    UseQueryOptions<T, unknown, T, (string | undefined)[]>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<T> {
  const { isAuthenticated } = useUser();

  const enabled = useMemo(() => {
    return options?.enabled !== undefined
      ? options.enabled && isAuthenticated === true
      : isAuthenticated === true;
  }, [options?.enabled, isAuthenticated]);

  return useQuery(queryKey, (context) => queryFn(context), {
    ...options,
    enabled,
  });
}
