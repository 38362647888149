import { createContext, useEffect, useMemo, useState } from "react";
import { useUser } from "./UserContext";
import Dialog from "@/components/Dialog";
import SadFaceIcon from "/assets/icons/sad-face.svg";
import { noAuthRequired } from "./ProtectRoutesContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useStages } from "./StagesContext";

type SessionDialogsContext = {};

const SessionDialogsContext = createContext<SessionDialogsContext>(
  {} as SessionDialogsContext
);

type SessionDialogsProps = {
  children: React.ReactNode;
};

export const SessionDialogsProvider = ({ children }: SessionDialogsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const { currentStageNumber, isStageClosed, isLoading } = useStages();

  const [isOpenInvalidStageDialog, setIsOpenInvalidStageDialog] =
    useState(false);
  const [isOpenCheckProfileDialog, setIsOpenCheckProfileDialog] =
    useState(false);

  const isAuthenticatedPage = useMemo(() => {
    return !noAuthRequired.includes(location.pathname);
  }, [location.pathname]);

  const userId = useMemo(() => userInfo?.id.toString(), [userInfo?.id]);

  // did not advance stage dialog
  useEffect(() => {
    if (!userId || !isStageClosed || isLoading || !isAuthenticatedPage) {
      return;
    }

    const invalidStageDialogUserId = localStorage.getItem(
      "invalidStageDialogUserId"
    );

    if (invalidStageDialogUserId !== userId) {
      setIsOpenInvalidStageDialog(true);
      localStorage.setItem("invalidStageDialogUserId", userId);
    }
  }, [isLoading, isStageClosed, userId, isAuthenticatedPage]);

  // complete profile dialog
  useEffect(() => {
    if (
      !userId ||
      currentStageNumber > 1 ||
      isLoading ||
      !isAuthenticatedPage ||
      isStageClosed
    ) {
      return;
    }

    const completeProfileDialogUserId = localStorage.getItem(
      "completeProfileDialogUserId"
    );

    if (completeProfileDialogUserId !== userId) {
      setIsOpenCheckProfileDialog(true);
      localStorage.setItem("completeProfileDialogUserId", userId);
    }
  }, [
    isLoading,
    currentStageNumber,
    userId,
    isAuthenticatedPage,
    isStageClosed,
  ]);

  return (
    <SessionDialogsContext.Provider value={{}}>
      {children}
      <Dialog
        isOpen={isOpenInvalidStageDialog}
        icon={<img src={SadFaceIcon} />}
        text="Infelizmente não foi dessa vez. Você não avançou para a próxima etapa. Mas não deixe de tentar na próxima edição."
        onConfirm={() => setIsOpenInvalidStageDialog(false)}
      />

      <Dialog
        isOpen={isOpenCheckProfileDialog}
        text="Importante: Confirme seus dados. Estamos chegando no fim da primeira etapa, verifique se todos os seus dados estão corretos."
        confirmButtonText="Confirmar dados"
        onConfirm={() => {
          navigate("/profile");
          setIsOpenCheckProfileDialog(false);
        }}
      />
    </SessionDialogsContext.Provider>
  );
};
