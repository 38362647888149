import { useLoader } from "@/context/LoaderContext";
import { getCompletedCourses } from "@/services/courses";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";

export function useGetCompletedCourses() {
  const loader = useLoader();

  const { data, isLoading } = useQuery("completed-courses", () =>
    getCompletedCourses()
  );

  const completedCoursesCount = useMemo(() => {
    if (isLoading) return 0;

    return data?.completedCourses ?? 0;
  }, [isLoading, data?.completedCourses]);

  useEffect(() => {
    if (isLoading) {
      loader.show("get-completed-courses");
    } else {
      loader.hide("get-completed-courses");
    }
  }, [isLoading]);

  return { completedCoursesCount, isLoading };
}
