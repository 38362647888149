import Spacer from "@/components/Spacer";
import Text from "@/components/Text";
import AuthWrapper from "@/layout/AuthWrapper";
import { Box, CircularProgress } from "@mui/material";
import Button from "@/components/Button";
import { useSignin } from "./useSignIn";
import { useReality } from "@/context/RealityContext";
import Dialog from "@/components/Dialog";
import PhoneInput from "@/components/PhoneInput";
import ResendCodeButton from "@/components/ResendCodeButton";
import Theme from "@/utils/theme";
import { OtpCodeInput } from "../SignUp/styles";

export default function SignIn() {
  const {
    currentStep,
    setCurrentStep,

    phoneNumber,
    setPhoneNumber,
    phoneNumberError,

    otp,
    setOtp,

    onClickSignIn,
    isLoading,
    onClickSignUp,
    isOpenChooseValidation,

    showSubscriptionsEndedDialog,
    toggleSubscriptionsEndedDialog,

    smsTimerValue,
    sendSignInSmsCode,
    onSendWhatsappCode,

    showNoAccountDialog,
    setShowNoAccountDialog,
    resendCode,
  } = useSignin();
  const { isSubscriptionOpen } = useReality();

  return (
    <AuthWrapper
      pageTitle="FAÇA O LOGIN"
      stepsSubtitle="Cadastre-se e conclua os desafios!"
      stepsDescription="Após concluir seu cadastro, faça o login e acesse a página do participante para pontuar concluindo cursos e desafios e avançar na competição."
      onClickBack={
        currentStep > 1 ? () => setCurrentStep(currentStep - 1) : undefined
      }
    >
      <div id="recaptcha-container" />
      <Box display="flex" flexDirection="column">
        <Text fontSize="1rem" mt="0.5rem" mb="1rem" alignSelf="center">
          Entre aqui
        </Text>

        {currentStep === 1 ? (
          <PhoneInput
            label="Telefone"
            value={phoneNumber}
            setValue={setPhoneNumber}
            error={!!phoneNumberError}
            errorText={phoneNumberError}
          />
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <OtpCodeInput
              value={otp}
              onChange={setOtp}
              onSubmit={onClickSignIn}
              isInvalid={false}
            />

            <Spacer height="2rem" />
            <Text color={Theme.colors.text.secondary}>
              Tempo de reenvio do código: {smsTimerValue}
            </Text>

            <Spacer height="2.5rem" />
            <ResendCodeButton
              disabled={smsTimerValue > 0}
              onClick={resendCode}
            />
          </Box>
        )}

        <Spacer height="2rem" />
        <Button size="large" onClick={onClickSignIn}>
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} size="1rem" />
          ) : (
            "Entrar"
          )}
        </Button>

        <Spacer height="1rem" />
        <Button
          size="large"
          color="secondary"
          onClick={onClickSignUp}
          disabled={!isSubscriptionOpen}
        >
          Quero me cadastrar
        </Button>
      </Box>

      <Dialog
        isOpen={showNoAccountDialog}
        text="Este telefone não possui cadastro, crie sua conta para prosseguir."
        confirmButtonText="Criar Conta"
        onConfirm={onClickSignUp}
        onClose={() => setShowNoAccountDialog(false)}
      />

      <Dialog
        isOpen={showSubscriptionsEndedDialog}
        text="Período de inscrições foi encerrado."
        onConfirm={toggleSubscriptionsEndedDialog}
      />

      <Dialog
        isOpen={isOpenChooseValidation}
        text={`Escolha uma opção abaixo.`}
        confirmButtonText="Receber código por SMS"
        onConfirm={() => sendSignInSmsCode()}
        closeButtonText="Receber código por Whatsapp"
        onClose={() => onSendWhatsappCode()}
      />
    </AuthWrapper>
  );
}
