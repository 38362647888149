import { memo, useState } from "react";
import { PhoneInputProps } from "./props.type";
import { Box } from "@mui/material";
import MaskedInput from "../MaskedInput";

const PhoneInput = ({ ContainerProps, ...props }: PhoneInputProps) => {
  const [mask, setMask] = useState("(99) 99999-9999");

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const rawValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
    if (rawValue.length === 10) {
      setMask("(99) 9999-9999");
    } else {
      setMask("(99) 99999-9999");
    }

    props.onBlur?.(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setMask("(99) 99999-9999");

    props.onFocus?.(e);
  };

  return (
    <Box {...ContainerProps}>
      <MaskedInput
        mask={mask}
        blockChars={[" "]}
        {...props}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </Box>
  );
};

export default memo(PhoneInput);
