import { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";

type Redirect = {};

const Redirect = createContext<Redirect>({} as Redirect);

type RedirectProviderProps = {
  children: React.ReactNode;
};

export const redirectLoggedUserRoutes = ["/sign-in", "/sign-up"];

export const RedirectProvider = ({ children }: RedirectProviderProps) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    if (
      location.pathname === "/" ||
      (isAuthenticated === true &&
        redirectLoggedUserRoutes.includes(location.pathname))
    ) {
      navigate("/dashboard");
    }
  }, [location.pathname, isAuthenticated]);

  return <Redirect.Provider value={{}}>{children}</Redirect.Provider>;
};
