import { getCourses } from "@/services/courses";
import { getAllRealityStages } from "@/services/stages";
import { StageWithChallenge } from "@/services/stages/types/stages.types";
import { realityInfo } from "@/utils/realityInfo";
import { useMemo } from "react";
import { useQuery } from "react-query";

export const useRegulation = () => {
  const { data: stages, isLoading: isLoadingStages } = useQuery(
    "all-stages",
    () => getAllRealityStages()
  );
  const { data: courses, isLoading: isLoadingCourses } = useQuery(
    "get-courses",
    () => getCourses()
  );

  const onOpenRegulation = () => {
    if (realityInfo.regulationUrl) {
      window.open(realityInfo.regulationUrl);
    }
  };

  const lastChallengeId = useMemo(() => {
    if (!stages) return -1;

    const lastStageIndex = stages.length - 1;

    const challenges = stages[lastStageIndex].challenges;
    const lastChallengeIndex = challenges.length - 1;

    return challenges[lastChallengeIndex]?.id;
  }, [stages]);

 

  const formattedStages = useMemo(() => {
    if (isLoadingStages || !stages?.length) return [];

    type ItemType = {
      id?: number;
      title: string;
      points: number;
    };
    type FormattedType = ({ items: ItemType[] } & Omit<
      StageWithChallenge,
      "challenges"
    >)[];

    const aux: FormattedType = stages.map((s) => ({
      ...s,
      items: s.challenges.map((challenge) => ({
        ...challenge,
        title: `DESAFIO ${challenge.order}: ${challenge.shortTitle}`,
      })),
    }));


    return aux;
  }, [isLoadingStages, stages]);

  const formattedCourses = useMemo(() => {
    if (isLoadingCourses || !courses?.length) return [];
    return courses.map((s, index) => ({
      ...s,
      id: index,
      title: `Curso: ${s.title} `,
      points: s.points || 0, // Ensure points property is added
    }));
  }, [isLoadingCourses, courses]);

  const totalPointsCourse = useMemo(() => {
    if (!formattedCourses) return 0;
    return formattedCourses.reduce((acc, course) => acc + course.points, 0);
  }, [formattedCourses]);
 
  return {
    onOpenRegulation,
    stages: formattedStages,
    lastChallengeId,
    formattedCourses,
    totalPointsCourse
  };
};
